import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import Card from '../Card'
// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import CreateBlogPostModal from './createBlogPostModal';

const CreateBlogPostInputBox = ({ postType, refreshApi, blogId }) => {
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";
    let generalSettingsData = (useSelector((state) => state.setting.setting.general_settings))
       generalSettingsData = generalSettingsData ? JSON.parse(generalSettingsData) : "";
    
    const [createBlogPostModalShow, setCreateBlogPostModalShow] = useState(false);


    return (
        <>
            <Card id="post-modal-data" >
                <div className="card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">Create {generalSettingsData?.blog_heading_text}</h4>
                    </div>
                </div>
                <Card.Body>
                    <div className="d-flex align-items-center">
                        <div className="user-img">
                            <img loading="lazy" src={profileData?.user_setting?.photo ? profileData?.user_setting?.photo :
                                "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="userimg" className="avatar-60 rounded-circle" />
                        </div>
                        <form className="post-text ms-3 w-100 " onClick={() => setCreateBlogPostModalShow(true)}>
                            <input type="text" className="form-control rounded" placeholder="Write something here..." style={{ border: "none" }} />
                        </form>
                    </div>
                </Card.Body>
            </Card>
            {
                createBlogPostModalShow ?
                    <CreateBlogPostModal postType={postType} refreshApi={refreshApi} createBlogPostModalShow={createBlogPostModalShow}
                    setCreateBlogPostModalShow={setCreateBlogPostModalShow} blogId={blogId} blogData={""}/> : null
            }
        </>
    )
}

export default CreateBlogPostInputBox
