import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Modal } from 'react-bootstrap'
import Card from '../../../components/Card'
import { InView } from "react-intersection-observer";
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../../api/apihandler';
import { BLOCK_UNBLOCK_FOLLOWER, FOLLOWER_LIST, FOLLOW_UNFOLLOW } from "../../../api/constants";
import LoaderModal from '../../../components/loaderModal';

//profile-header
import ProfileHeader from '../../../components/profile-header'

// image
import img1 from '../../../assets/images/page-img/profile-bg2.jpg'
import img2 from '../../../assets/images/page-img/profile-bg1.jpg'
import img3 from '../../../assets/images/page-img/profile-bg3.jpg'
import img4 from '../../../assets/images/page-img/profile-bg4.jpg'
import img5 from '../../../assets/images/page-img/profile-bg5.jpg'
import img6 from '../../../assets/images/page-img/profile-bg6.jpg'
import img7 from '../../../assets/images/page-img/profile-bg7.jpg'
import img8 from '../../../assets/images/page-img/profile-bg8.jpg'
import img9 from '../../../assets/images/page-img/profile-bg9.jpg'
import user05 from '../../../assets/images/user/05.jpg'
import user06 from '../../../assets/images/user/06.jpg'
import user07 from '../../../assets/images/user/07.jpg'
import user08 from '../../../assets/images/user/08.jpg'
import user09 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
import user13 from '../../../assets/images/user/13.jpg'
import user14 from '../../../assets/images/user/14.jpg'
import user15 from '../../../assets/images/user/15.jpg'
import user16 from '../../../assets/images/user/16.jpg'
import user17 from '../../../assets/images/user/17.jpg'
import user18 from '../../../assets/images/user/18.jpg'
import user19 from '../../../assets/images/user/19.jpg'
import loader from '../../../assets/images/page-img/page-load-loader.gif'
import NoDataFound from '../../../components/noDataFound';

const FriendList = () => {

   const navigate = useNavigate();
   const [followerList, setFollowerList] = useState([]);
   const [page, setPage] = useState(1);
   const [maxPage, setMaxPage] = useState(1);
   const [showLoader, setShowLoader] = useState(false);
   const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);

   const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsFirst(false)
    }, [isFirst]);

   useEffect(() => {
      //console.log("token",localStorage.getItem("authToken"))
      if (localStorage.getItem("uzoneAuthToken")) {
         setFollowerList([])
         getFollowerList(1)
      } else {
         navigate('/')
      }
   }, []);

   const loadMore = (inView) => {
      if (inView) {
         if (page < maxPage) {
            getFollowerList(page + 1)
            //console.log("scroll working...", page + 1)
            setPage(page + 1)
         }
      }
   };

   async function getFollowerList(pageVal) {
      setShowMoreDataLoader(true)
      //setShowLoader(true)
      try {
         const res = await apiHandler(FOLLOWER_LIST, "POST", {
            page: pageVal,
            limit: 10
         })
         if (res.data?.response?.status === 200) {
            setMaxPage(res?.data?.data?.followers?.meta?.last_page)
            //console.log("profileRes", maxPage)
            if (res?.data?.data?.followers?.meta?.total > 0) {
               if (pageVal === 1) {
                  setFollowerList(res?.data?.data?.followers?.data)
               } else {
                  setFollowerList(followerList.concat(res?.data?.data?.followers?.data))
               }
            }
         } else {
            navigate('/')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      }
      finally {
         setShowMoreDataLoader(false)
         //setShowLoader(false)
      }
   }

   async function getFollowUnfollow(followerId, index, isFollowingVal) {
      try {
         const res = await apiHandler(FOLLOW_UNFOLLOW, "POST", {
            follower_id: followerId
         })
         if (res.data?.response?.status === 200) {
            let temporaryarray = followerList.slice();
            temporaryarray[index]['is_following'] = isFollowingVal;
            setFollowerList(temporaryarray);
         } else {
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
         }
      } catch (error) {
         //console.error(error);
      }
      finally {
      }
   }

   async function getBlockUnblock(followerId, index, isFollowingVal) {
      try {
         const res = await apiHandler(BLOCK_UNBLOCK_FOLLOWER, "POST", {
            follower_id: followerId
         })
         if (res.data?.response?.status === 200) {
            let temporaryarray = followerList.slice();
            temporaryarray[index]['isblocked'] = isFollowingVal;
            setFollowerList(temporaryarray);
         } else {
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
         }
      } catch (error) {
         //console.error(error);
      }
      finally {
      }
   }


   return (
      <>
         <LoaderModal showLoader={showLoader}/>
         <div id='content-page' className='content-page'>
            <Container>
               <Row>
                  <Col sm="12">
                     <Card>
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">Follower List</h4>
                           </div>
                        </Card.Header>
                        <Card.Body>
                           <ul className="request-list m-0 p-0">
                              {followerList?.length > 0 ? followerList.map((elm, i) => (
                                 <li className="d-flex align-items-center  flex-wrap">
                                    <div className="user-img img-fluid flex-shrink-0">
                                       <img src={elm?.follower?.user_setting?.photo ? elm?.follower?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                          alt="story-img" className="rounded-circle avatar-40" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                       <Link to={`/dashboard/app/friend-profile/${elm?.follower?.slug}`}>
                                          <h5>{elm?.follower?.first_name && (elm?.follower?.first_name + " " + elm?.follower?.last_name)}</h5>
                                       </Link>
                                       <p className="mb-0">{elm?.followers_count + " Followers " + " ● " + elm?.followings_count + " Following "}</p>
                                       {/* <p className="mb-0">{elm?.follower?.slug}</p> */}
                                    </div>
                                    <div className="d-flex align-items-center mt-2 mt-md-0">
                                       {
                                          elm?.is_following == 0 ?
                                             <Link to="#" className="me-3 btn btn-primary rounded"
                                                onClick={() => {
                                                   getFollowUnfollow(elm?.follower_id, i, 1)
                                                }}><i className="ri-user-add-line me-1"></i>Follow</Link> :
                                             <Link to="#" className="me-3 btn btn-secondary rounded"
                                                onClick={() => {
                                                   getFollowUnfollow(elm?.follower_id, i, 0)
                                                }}>Following</Link>
                                       }
                                       {
                                          elm?.isblocked == 0 ?
                                             <Link to="#" className="me-3 btn btn-primary rounded"
                                                onClick={() => {
                                                   getBlockUnblock(elm?.follower_id, i, 1)
                                                }}>
                                                {/* <i className="ri-user-add-line me-1"></i> */}
                                                Block</Link> :
                                             <Link to="#" className="me-3 btn btn-secondary rounded"
                                                onClick={() => {
                                                   getBlockUnblock(elm?.follower_id, i, 0)
                                                }}>Unblocked</Link>
                                       }

                                       {/* <Link to="#" className="me-3 btn btn-primary rounded"
                                         onClick={() => {
                                            setPage(page + 1)
                                            getPeopleYouMayKnow()
                                         }}><i className="ri-user-add-line me-1"></i>Follow</Link> */}
                                       {/* <Link to="#" onClick={questionAlert} className="btn btn-secondary rounded" data-extra-toggle="delete" data-closest-elem=".item">Remove</Link> */}
                                    </div>
                                 </li>
                              )) : <NoDataFound/>}
                              {
                                 showMoreDataLoader ?
                                    <div className="col-sm-12 text-center">
                                       <img src={loader} alt="loader" style={{ height: "100px" }} />
                                    </div> : null
                              }
                              <InView as="div" onChange={(inView, entry) => loadMore(inView)} />
                           </ul>
                        </Card.Body>
                     </Card>

                  </Col>
               </Row>
            </Container>
         </div>
      </>
   )
}


//     return (
//         <>
//             <ProfileHeader title="Follower List" img={img3} />
//             <div id="content-page" className="content-page">
//                 <Container>
//                     <Row>
//                         {followerList?.length > 0 ? followerList.map((elm, i) => (
//                             <Col md={6}>
//                                 <Card className=" card-block card-stretch card-height">
//                                     <Card.Body className=" profile-page p-0">
//                                         <div className="profile-header-image">
//                                             <div className="cover-container">
//                                                 <img loading="lazy" src={img1} alt="profile-bg" className="rounded img-fluid w-100" />
//                                             </div>
//                                             <div className="profile-info p-4">
//                                                 <div className="user-detail">
//                                                     <div className="d-flex flex-wrap justify-content-between align-items-start">
//                                                         <div className="profile-detail d-flex">
//                                                             <div className="profile-img pe-4">
//                                                                 <img style={{height: 120, width: 120}} loading="lazy" src={elm?.follower?.user_setting?.photo ?
//                                                                     elm?.follower?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
//                                                                     alt="profile-img" className="avatar-130 img-fluid" />
//                                                             </div>
//                                                             <div className="user-data-block">
//                                                                 <h4>
//                                                                     <Link to={`/dashboard/app/friend-profile/${elm?.follower?.slug}`}>{elm?.follower?.first_name && (elm?.follower?.first_name + " " + elm?.follower?.last_name)}</Link>
//                                                                 </h4>
//                                                                 <h6>{elm?.follower?.slug && (elm?.follower?.slug)}</h6>
//                                                                 {/* <p>Lorem Ipsum is simply dummy text of the</p> */}
//                                                             </div>
//                                                         </div>
//                                                         {
//                                                             elm?.isblocked == 0 ?
//                                                                 <Link to="#" className="me-3 btn btn-primary rounded"
//                                                                     onClick={() => {
//                                                                         getFollowUnfollow(elm?.follower_id, i, 1)
//                                                                     }}>Block</Link> :
//                                                                 <Link to="#" className="me-3 btn btn-secondary rounded"
//                                                                     onClick={() => {
//                                                                         getFollowUnfollow(elm?.follower_id, i, 0)
//                                                                     }}>Unblocked</Link>
//                                                         }
//                                                         {/* <button type="submit" className="btn btn-primary"> Block User</button> */}
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                         )) : null
//                         }
//                         {
//                             page < maxPage ?
//                                 <li className="d-block text-center mb-0 pb-0">
//                                     <Link to="#" className="me-3 btn btn-primary"
//                                         onClick={() => {
//                                             setPage(page + 1)
//                                             getFollowerList()
//                                         }}>View More Request</Link>
//                                 </li> : null
//                         }
//                     </Row>
//                 </Container>
//             </div>
//         </>
//     )

// }

export default FriendList;