import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, Image } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import ShareOffcanvas from '../share-offcanvas'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { FEED_DELETE, STORIES_LIST, STORIES_VIEW } from "../../api/constants";
import Moment from 'moment';
import Stories, { WithHeader, WithSeeMore } from 'react-insta-stories';
import Swal from 'sweetalert2'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import cross from '../../assets/images/icon/cross.png'
import video from '../../assets/images/small/video.png'

import s1 from '../../assets/images/page-img/s1.jpg'
import s2 from '../../assets/images/page-img/s2.jpg'
import s3 from '../../assets/images/page-img/s3.jpg'
import s4 from '../../assets/images/page-img/s4.jpg'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
// import state selectors
import { setIsOptimizing, setPostModalShow, setCreatePostType  } from '../../store/setting/actions'
import NoDataFound from '../noDataFound'
import StoriesCommentBox from './storiesCommentBox'
import CreatePostModal from '../createPost/createPostModal'

const StoriesSection = ({ isMyStory }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [storiesList, setStoriesList] = useState([]);
    const [storiesMediaList, setStoriesMediaList] = useState([]);
    const [storiesIndex, setStoriesIndex] = useState('');
    const post_modal_show = useSelector((state) => state.setting.setting.post_modal_show);
    const [showViewCommentModal, setShowViewCommentModal] = useState(false);
    const [imageId, setImageId] = useState(null);
    const [storyViewList, setStoryViewList] = useState([]);
    const [storyCommentList, setStoryCommentList] = useState([]);
    const [createPostModalShow, setCreatePostModalShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleViewCommentClose = () => {
        // setShow(false);
        setShowViewCommentModal(false);
    }

    useEffect(() => {
        getStoriesList()
    }, [])

    // useEffect(() => {
    //     if (!post_modal_show) {
    //         getStoriesList()
    //     }
    // }, [post_modal_show])

    // useEffect(() => {
    //     setStoryViewList(storiesList[storiesIndex]?.user_post_images[viewCommentIndex]?.views)
    //     setStoryCommentList(storiesList[storiesIndex]?.user_post_images[viewCommentIndex]?.comments)
    //     setShowViewCommentModal(true);
    // }, [viewCommentIndex != null])

    const stories = [
        {
            url: 'https://uzone360live.s3.amazonaws.com/feedVideos/video3.mp4',
            duration: 5000,
            type: 'video',
            header: {
                heading: 'Mohit Karekar',
                subheading: 'Posted 30m ago',
                profileImage: 'https://picsum.photos/100/100',
            },
        },
    ];

    async function createMedia(elm) {
        let tempArray = []
        elm?.user_post_images.map((image, i) => {
            if (!tempArray.includes(image?.image_path)) {
                if (image?.media_type === 1) {
                    let myObject = null
                    if (elm?.is_own === 1) {
                        myObject = {
                            url: image?.image_path,
                            duration: 5000,
                            type: 'video',
                            // seeMore: ({ }) => {
                            //     return seeMoreFunctionality(image)
                            // },
                            // header: {
                            //     heading: `${elm?.user?.first_name + " " + elm?.user?.last_name}`,
                            //     subheading: `${(image?.views?.length > 0 ? image?.views?.length : 0) + " Views "
                            //         + (image?.comments?.length > 0 ? image?.comments?.length : 0) + " Comments"}`,
                            //     profileImage: `${elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}`,
                            // }
                        };
                    } else {
                        myObject = {
                            url: image?.image_path,
                            duration: 5000,
                            type: 'video',
                            // header: {
                            //     heading: `${elm?.user?.first_name + " " + elm?.user?.last_name}`,
                            //     subheading: `${elm?.content ? elm?.content : " "}`,
                            //     profileImage: `${elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}`,
                            // }
                        };
                    }
                    tempArray = [...tempArray, myObject]
                    getStoryView(image?.id)
                } else {
                    let myObject = null
                    if (elm?.is_own === 1) {
                        myObject = {
                            url: image?.image_path,
                            duration: 5000,
                            type: 'image',
                            // seeMore: ({ }) => {
                            //     return seeMoreFunctionality(image)
                            // },
                            // header: {
                            //     heading: `${elm?.user?.first_name + " " + elm?.user?.last_name}`,
                            //     subheading: `${(image?.views?.length > 0 ? image?.views?.length : 0) + " Views "
                            //         + (image?.comments?.length > 0 ? image?.comments?.length : 0) + " Comments"}`,
                            //     profileImage: `${elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}`,
                            // }
                        };
                    } else {
                        myObject = {
                            url: image?.image_path,
                            duration: 5000,
                            type: 'image',
                            // header: {
                            //     heading: `${elm?.user?.first_name + " " + elm?.user?.last_name}`,
                            //     subheading: `${elm?.content ? elm?.content : " "}`,
                            //     profileImage: `${elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}`,
                            // }
                        };
                    }
                    tempArray = [...tempArray, myObject]
                    getStoryView(image?.id)
                }
            }
        })
        setStoriesMediaList(tempArray)
        //console.log("arrayList", storiesMediaList)
    }

    const seeMoreFunctionality = ({ elm }) => {
        setStoryViewList(elm?.views)
        setStoryCommentList(elm?.comments)
        setShowViewCommentModal(true);
        setImageId(elm?.id)
    }

    async function getStoriesList() {
        // console.log("ApiCall1")
        if (isMyStory === false) {
            // console.log("ApiCall2")
            try {
                const res = await apiHandler(STORIES_LIST, "POST", {
                    page: 1,
                    limit: 100,
                    mystory: 1
                })
                if (res.data?.response?.status === 200) {
                    // console.log("ApiCall3")
                    // setStoriesList(res?.data?.data?.stories?.data);
                    const tempArray = res?.data?.data?.stories?.data
                    const res1 = await apiHandler(STORIES_LIST, "POST", {
                        page: 1,
                        limit: 100,
                        mystory: 0
                    })
                    if (res1.data?.response?.status === 200) {
                        const tempArray1 = res1?.data?.data?.stories?.data
                        setStoriesList(tempArray.concat(tempArray1));
                    }
                }
            } catch (error) {
                console.error(error);
            }
            finally {
            }

        } else {
            try {
                // console.log("ApiCall5")
                const res = await apiHandler(STORIES_LIST, "POST", {
                    page: 1,
                    limit: 100,
                    mystory: isMyStory ? 1 : 0
                })
                if (res.data?.response?.status === 200) {
                    // console.log("ApiCall6")
                    setStoriesList(res?.data?.data?.stories?.data)
                } else {
                }
            } catch (error) {
                console.error(error);
            }
            finally {
            }
        }

    }

    async function getStoryView(imageId) {
        try {
            const res = await apiHandler(STORIES_VIEW, "POST", {
                image_id: imageId
            })
            if (res.data?.response?.status === 200) {

            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    async function getFeedDelete(post_id) {
        if (localStorage.getItem("uzoneAuthToken")) {
            try {
                const res = await apiHandler(FEED_DELETE, "DELETE", {
                    post_id: post_id
                })
                if (res.data?.response?.status === 200) {
                    Swal.fire({
                        title: 'Success',
                        text: res?.response?.data?.data?.message,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    })
                    getStoriesList()
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: res?.response?.data?.data?.message,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                }
            } catch (error) {
                console.error(error);
            }
            finally {
            }
        } else {
            //navigate('/auth/sign-in')
        }
    }


    return (
        <>
            <div>
                <Card>
                    <div className="card-header d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">Stories</h4>
                        </div>
                    </div>
                    <Card.Body>
                        <ul className="media-story list-inline m-0 p-0">
                            <li className="d-flex align-items-center" style={{ cursor: "pointer" }}
                                onClick={() => {
                                    dispatch(setIsOptimizing(false));
                                    setCreatePostModalShow(true)
                                    // dispatch(setCreatePostType("1"));
                                    // dispatch(setPostModalShow(true));
                                }}>
                                <i className="ri-add-line"></i>
                                <div className="stories-data ms-3">
                                    <h5>Create Your Story</h5>
                                    <p className="mb-0">time to story</p>
                                </div>
                            </li>
                            {
                                storiesList?.map((elm, i) => (
                                    <li className="d-flex mb-0 mt-2 justify-content-between  active"
                                        key={elm?.id} style={{ cursor: "pointer" }}>
                                        <img src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                            alt="story-img" className="rounded-circle avatar-60"
                                            onClick={() => {
                                                setStoriesIndex(i)
                                                createMedia(elm)
                                                setShow(true)
                                            }} />
                                        <div className="stories-data ms-3 d-flex align-items-center flex-wrap"
                                            onClick={() => {
                                                setStoriesIndex(i)
                                                createMedia(elm)
                                                setShow(true)
                                            }}>
                                            <h5 className="mb-0">{elm?.user?.first_name + " " + elm?.user?.last_name}</h5>
                                            <p style={{ padding: "0px" }}>{Moment(elm?.created_at).format('DD MMMM YYYY')}</p>
                                        </div>
                                        <div className="card-post-toolbar">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="bg-transparent">
                                                    <span className="material-symbols-outlined">
                                                        more_horiz
                                                        {/* more_vert */}
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className=" m-0 p-0">
                                                    {
                                                        elm?.is_own === 1 ?
                                                            <Dropdown.Item className=" p-3" to="#">
                                                                <div className="d-flex align-items-top"
                                                                    onClick={() =>
                                                                        Swal.fire({
                                                                            title: 'Warning!',
                                                                            text: 'Are you sure, want to delete this story?',
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            showConfirmButton: true,
                                                                            cancelButtonText: 'Cancel',
                                                                            confirmButtonText: 'Confirm',
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                getFeedDelete(elm?.id)
                                                                            }
                                                                        })}>
                                                                    <i className="ri-delete-bin-7-line h4"></i>
                                                                    <div className="data ms-2">
                                                                        <h6>Delete</h6>
                                                                        <p className="mb-0">Remove this story from your profile</p>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Item> : null
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </li>
                                ))
                            }
                            {/* <li className="d-flex mb-3 align-items-center active">
                                <img src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="story-img" className="rounded-circle img-fluid" />
                                <div className="stories-data ms-3">
                                    <h5>Anna Mull</h5>
                                    <p className="mb-0">1 hour ago</p>
                                </div>
                            </li>
                            <li className="d-flex mb-3 align-items-center">
                                <img src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="story-img" className="rounded-circle img-fluid" />
                                <div className="stories-data ms-3">
                                    <h5>Anna Mull</h5>
                                    <p className="mb-0">1 hour ago</p>
                                </div>
                            </li> */}
                        </ul>
                        {/* {
                            storiesList?.length > 0 ?
                                <Link to="#" className="btn btn-primary d-block mt-3">See All</Link> : null
                        } */}
                    </Card.Body>
                </Card>


                <Modal scrollable={true} show={show} onHide={handleClose} size="md" style={{ marginTop: 50, maxHeight: "720px" }}>
                    <Modal.Header className="d-flex justify-content-between">
                        <div className="d-flex justify-content-between">
                            <div className="me-3">
                                <img loading="lazy" className="rounded-circle  avatar-50"
                                    src={storiesList[storiesIndex]?.user?.user_setting?.photo ? storiesList[storiesIndex]?.user?.user_setting?.photo :
                                        "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                            </div>
                            <div className="w-100">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div>
                                        <h5 className="mb-0 d-inline-block"><Link to="#">{storiesList[storiesIndex]?.user?.first_name + " " + storiesList[storiesIndex]?.user?.last_name}</Link></h5>
                                        <p className="mb-0">{storiesList[storiesIndex]?.content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div style={{minHeight: "200px"}}>
                                <Stories
                                    stories={storiesMediaList}
                                    width={'100%'}
                                    height={'100%'}
                                />
                            </div>
                            {
                                storiesList[storiesIndex]?.is_own === 1 ?
                                    <p className="mb-0 d-inline-block" onClick={() => {
                                        setStoryViewList(storiesList[storiesIndex]?.user_post_images[0]?.views)
                                        setImageId(storiesList[storiesIndex]?.user_post_images[0]?.id)
                                        setShowViewCommentModal(true)
                                    }}>
                                        <Link to="#">
                                            {(storiesList[storiesIndex]?.user_post_images[0]?.views?.length > 0 ? storiesList[storiesIndex]?.user_post_images[0]?.views?.length : 0) + " Views "
                                                + (storiesList[storiesIndex]?.user_post_images[0]?.comments?.length > 0 ? storiesList[storiesIndex]?.user_post_images[0]?.comments?.length : 0) + " Comments"}
                                        </Link>
                                    </p> :
                                    <div className="w-100">
                                        <StoriesCommentBox postId={storiesList[storiesIndex]?.user_post_images[0]?.id} commentId={""} setRefreshCommentList={getStoriesList} />
                                    </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal scrollable={true} show={showViewCommentModal} onHide={handleViewCommentClose} size="md" style={{ marginTop: 50, maxHeight: "630px", marginLeft: "-3px" }}>
                    <Modal.Header className="d-flex justify-content-between">
                        <div className="d-flex justify-content-between">
                            <div className="me-3">
                                <img loading="lazy" className="rounded-circle  avatar-50"
                                    src={storiesList[storiesIndex]?.user?.user_setting?.photo ? storiesList[storiesIndex]?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} />
                            </div>
                            <div className="w-100">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div>
                                        <h5 className="mb-0 d-inline-block"><Link to="#">{storiesList[storiesIndex]?.user?.first_name + " " + storiesList[storiesIndex]?.user?.last_name}</Link></h5>
                                        <p className="mb-0">{Moment(storiesList[storiesIndex]?.created_at).format('DD MMMM YYYY')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" className="btn btn-secondary lh-1" onClick={handleViewCommentClose} ><span className="material-symbols-outlined">close</span></button>
                    </Modal.Header>
                    <Modal.Body>
                        <Tab.Container defaultActiveKey="first">
                            <Row>
                                <Col lg="12">
                                    <div>
                                        <Nav as="ul" variant="pills" className="iq-edit-profile1 row">
                                            <Nav.Item as="li" className="col-md-6 p-0">
                                                <Nav.Link eventKey="first" role="button">
                                                    Story Views
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li" className="col-md-6 p-0">
                                                <Nav.Link eventKey="second" role="button">
                                                    Story Comments
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first" className="fade show">
                                            {
                                                storyViewList?.length > 0 ? storyViewList?.map((elm, i) => (
                                                    <div className="d-flex align-items-center py-1 px-4 pt-2">
                                                        <div className="flex-shrink-0">
                                                            <Image
                                                                className="align-self-center img-fluid avatar-30 rounded-pill"
                                                                src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                                alt=""
                                                                loading="lazy" />
                                                        </div>
                                                        <div className="d-flex flex-column ms-3">
                                                            <h5>{elm?.user?.first_name + " " + elm?.user?.last_name}</h5>
                                                        </div>
                                                    </div>
                                                )) : <NoDataFound />
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second" className="fade show">
                                            {
                                                storyCommentList?.length > 0 ? storyCommentList?.map((elm, i) => (
                                                    <div className="d-flex align-items-center py-1 px-4">
                                                        <div className="flex-shrink-0">
                                                            <Image
                                                                className="align-self-center img-fluid avatar-30 rounded-pill"
                                                                src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                                alt=""
                                                                loading="lazy" />
                                                        </div>
                                                        <div className="d-flex flex-column ms-3">
                                                            <h6>{elm?.comment}</h6>
                                                        </div>
                                                    </div>
                                                )) : <NoDataFound />
                                            }
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <div className="w-100">
                            <StoriesCommentBox postId={imageId} commentId={""} setRefreshCommentList={getStoriesList} />
                        </div>
                    </Modal.Footer> */}
                </Modal>
            </div>
            {
                createPostModalShow ?
                    <CreatePostModal postType={"1"} refreshApi={getStoriesList} createPostModalShow={createPostModalShow}
                        setCreatePostModalShow={setCreatePostModalShow} eventId={""} /> : null
            }
        </>
    )
}

export default StoriesSection
