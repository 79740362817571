import React, { useState } from 'react'
import { Link, useNavigate, Route } from 'react-router-dom'

const ReadMoreText = ({ txtData, maxLength }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleReadMore = () => setIsExpanded(!isExpanded);

    return (
        <>
            <div className="mt-1">
                <p>
                    {
                        txtData?.length > 150 ?
                            <>
                                {isExpanded ? txtData : txtData.slice(0, maxLength) + "..."}
                                <Link style={{ fontWeight: "bold" }} onClick={toggleReadMore}>
                                    {isExpanded ? "Read Less" : "Read More"}
                                </Link>
                            </> : txtData
                    }
                </p>
            </div >
        </>
    )
}
export default ReadMoreText;