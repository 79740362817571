import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { LOGIN_WITH_EMAIL, LOGIN_WITH_EMAIL_OTP_MATCH, RESEND_OTP } from "../../api/constants";
import Moment from 'moment';
import Swal from 'sweetalert2'
import * as DOMPurify from 'isomorphic-dompurify';

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';


const PublicBlogCard = ({ elm }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {

    }, [])

    return (
        <>
            <div>
                <Card className="rounded  mb-0" >
                    <div className="event-images">
                        <Link to={`/dashboard/blog/publicBlogDetails/${elm?.slug}`}>
                            <img src={elm?.default_image?.file_name ? elm?.default_image?.file_name : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                className="img-fluid" alt="Responsive"
                                style={{ height: "186px", width: "100%", objectFit: "cover" }} />
                        </Link>
                    </div>
                    <Card.Body>
                        <div style={{ margin: "-5px" }}>
                            <h5><Link to={`/dashboard/blog/publicBlogDetails/${elm?.slug}`} className="mb-2" >{elm?.title}</Link></h5>
                            {
                                elm?.body ?
                                    // <p >
                                    //     {(elm?.body).substring(1, 350)}
                                    //     {elm?.body?.length > 350 ? "....." : null}
                                    // </p> :
                                    <h6 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((elm?.body).substring(0, 350) + (elm?.body?.length > 350 ? "..." : "")) }} />:
                                    null
                            }
                            <div className="blog-meta d-flex align-items-center mb-3 position-right-side">
                                <div className="date date me-2 d-flex align-items-center">
                                    <i className="material-symbols-outlined pe-0 md-18 text-primary" style={{ fontSize: "20px" }}>
                                        calendar_month
                                    </i>
                                    {Moment(elm?.created_at).format('DD MMMM yyy')}
                                </div>
                                <div className="comments date me-2 d-flex align-items-center" style={{ cursor: "pointer" }}>
                                    <i className="material-symbols-outlined md-18 text-primary" style={{ fontSize: "20px" }}
                                        onClick={() => { navigate('/auth/sign-in') }} >thumb_up_alt</i>
                                    <div style={{ marginLeft: "1px" }}>
                                        {elm?.like_count + " "}like
                                    </div>
                                </div>
                                <div className="comments date me-2 d-flex align-items-center" style={{ cursor: "pointer" }}
                                    onClick={() => { navigate('/auth/sign-in') }}>
                                    <i className="material-symbols-outlined pe-1 md-18 text-primary" style={{ fontSize: "20px" }}>
                                        mode_comment
                                    </i>{elm?.comment_count} comments
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>

            </div>

        </>
    )
}

export default PublicBlogCard
