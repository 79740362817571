import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Form, Tooltip, Button, Modal } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { handleImageUpload } from "../../api/uploads";
import { FEED_MEDIA_POST, FEED_POST, MY_BLOG_IMAGE_UPLOAD, MY_BLOG_POST } from "../../api/constants";
import Moment from 'moment';
import Swal from 'sweetalert2'
import EmojiPicker from 'emoji-picker-react';
//import VideoThumbnail from 'react-video-thumbnail';
import { VideoThumbnailGenerator } from 'browser-video-thumbnail-generator';
import LoaderModal from '../loaderModal'

import cross from '../../assets/images/icon/cross.png'
import video from '../../assets/images/small/video.png'
import small07 from '../../assets/images/small/07.png'
import small08 from '../../assets/images/small/08.png'
import small09 from '../../assets/images/small/09.png'
import small1 from '../../assets/images/small/07.png'
import small2 from '../../assets/images/small/08.png'
import small3 from '../../assets/images/small/09.png'
import small4 from '../../assets/images/small/10.png'
import small5 from '../../assets/images/small/11.png'
import small6 from '../../assets/images/small/12.png'
import small7 from '../../assets/images/small/13.png'
import small8 from '../../assets/images/small/14.png'
import playButton from '../../assets/images/icon/play_button.png'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
// import state selectors
import { setPostModalShow, setCreatePostType } from '../../store/setting/actions'

const CreateBlogPostModal = ({ postType, refreshApi, createBlogPostModalShow, setCreateBlogPostModalShow, blogId, blogData }) => {
   // const dispatch = useDispatch()
   let profileData = (useSelector((state) => state.setting.setting.user_data))
   profileData = profileData ? JSON.parse(profileData) : "";
   let generalSettingsData = (useSelector((state) => state.setting.setting.general_settings))
   generalSettingsData = generalSettingsData ? JSON.parse(generalSettingsData) : "";
   const [showLoader, setShowLoader] = useState(false);
   const [challengeExpireDate, setChallengeExpireDate] = useState("");
   const [postContent, setPostContent] = useState('');
   const [imageFileArray, setImageFileArray] = useState([]);
   const [imageArray, setImageArray] = useState([]);
   const [title, setTitle] = useState(blogData ? blogData?.title : "");
   const [description, setDescription] = useState(blogData ? blogData?.body : "");
   const [metaTitle, setMetaTitle] = useState(blogData ? blogData?.meta_title : "");
   const [metaDescription, setMetaDescription] = useState(blogData ? blogData?.meta_description : "");
   const [metaTags, setMetaTags] = useState("");
   const [redirectUrl, setRedirectUrl] = useState(blogData ? blogData?.link_url : "");
   const [youtubeUrl, setYoutubeUrl] = useState(blogData ? blogData?.youtube : "");
   const [publishDate, setPublishDate] = useState(blogData ? Moment(blogData?.publish_date).format('yyyy-MM-DD') : "");
   const [categoryIdArray, setCategoryIdArray] = useState([]);
   const [tagIdArray, setTagIdArray] = useState([]);

   // console.log("dateEdit", Moment(blogData?.publish_date).format('DD-MM-yyyy'))

   const handleClose = () => {
      setCreateBlogPostModalShow(false)
      //setShow(false);
      setImageArray([]);
      setImageFileArray([])
      setPostContent("")
      setChallengeExpireDate("")
      // dispatch(setPostModalShow(false))
   }

   const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
         const reader = new FileReader();
         reader.onloadend = async () => {
            // setImageFileArray([...imageFileArray, file])
            // setImageArray([...imageArray, reader.result])
            setImageFileArray([file])
            setImageArray([reader.result])
         };
         reader.readAsDataURL(file);
      }
   };


   useEffect(() => {

   }, [])

   function validateBlogPost() {
      if (title != "" && description != "" && publishDate != "") {
         if (postType === "add") {
            if (imageArray.length > 0) {
               handleBlogPostSubmit()
            } else {
               Swal.fire({
                  title: 'Warning!',
                  text: 'Please select a '+generalSettingsData?.blog_heading_text+' image!',
                  icon: 'warning',
                  confirmButtonText: 'Okay'
               })
            }
         } else {
            handleBlogPostSubmit()
         }
      } else {
         if (title === "") {
            Swal.fire({
               title: 'Warning!',
               text: 'Please enter '+generalSettingsData?.blog_heading_text+' title!',
               icon: 'warning',
               confirmButtonText: 'Okay'
            })
         } else if (description === "") {
            Swal.fire({
               title: 'Warning!',
               text: 'Please enter '+generalSettingsData?.blog_heading_text+' description!',
               icon: 'warning',
               confirmButtonText: 'Okay'
            })
         } else if (publishDate === "") {
            Swal.fire({
               title: 'Warning!',
               text: 'Please select '+generalSettingsData?.blog_heading_text+' publish date!',
               icon: 'warning',
               confirmButtonText: 'Okay'
            })
         } else {
            Swal.fire({
               title: 'Warning!',
               text: 'Please select a'+ generalSettingsData?.blog_heading_text+ ' image!',
               icon: 'warning',
               confirmButtonText: 'Okay'
            })
         }
      }
   };

   async function handleBlogPostSubmit() {
      setShowLoader(true)
      try {
         const res = await apiHandler(MY_BLOG_POST, "POST", {
            title: title,
            body: description,
            selected_type: 1,
            selected_featured: 1,
            publish_date: publishDate,
            post_id: blogId,
            meta_tags: metaTags,
            meta_description: metaDescription,
            meta_title: metaTitle,
            link_url: redirectUrl,
            youtube: youtubeUrl,
            // category_id: categoryIdArray,
            // tag_id: tagIdArray
         })
         if (res.data?.response?.status === 200) {
            if (postType === "add") {
               handleImagePostSubmit(res?.data?.data?.post?.id)
            } else {
               if (imageFileArray?.length > 0) {
                  handleImagePostSubmit(res?.data?.data?.post?.id)
               } else {
                  setShowLoader(false)
                  setCreateBlogPostModalShow(false)
                  refreshApi()
                  Swal.fire({
                     title: 'Success!',
                     text: generalSettingsData?.blog_heading_text + ' updated successfully!',
                     icon: 'success',
                     confirmButtonText: 'Okay'
                  })
               }
            }
         } else {
            setShowLoader(false)
         }
      } catch (error) {
         console.error(error);
         setShowLoader(false)
      }
      finally {
         //setShowLoader(false)
      }
   }

   async function handleImagePostSubmit(post_id) {
      if (imageFileArray.length > 0) {
         for (let elm of imageFileArray) {
            const url = await handleImageUpload(elm, `feedImages/${Date.now()}`, "image/jpeg")
            imagePostApi(url, post_id)
         }
      }
   };

   async function imagePostApi(url, post_id) {
      try {
         const res = await apiHandler(MY_BLOG_IMAGE_UPLOAD, "POST", {
            post_id: post_id,
            image_url: url,
            add_type: postType,
         })
         if (res.data?.response?.status === 200) {
            refreshApi()
            Swal.fire({
               title: 'Success!',
               text: postType === "add" ? generalSettingsData?.blog_heading_text+ ' uploaded successfully.' : generalSettingsData?.blog_heading_text+' updated successfully.',
               icon: 'success',
               confirmButtonText: 'Okay'
            })
         }
      } catch (error) {
         setShowLoader(false)
         console.error(error);
      }
      finally {
         setCreateBlogPostModalShow(false)
         setShowLoader(false)
      }
   }


   return (
      <>
         <LoaderModal showLoader={showLoader} />
         <Modal scrollable={true} show={createBlogPostModalShow} onHide={handleClose} size="lg" style={{ marginTop: 90, maxHeight: "630px" }}>
            <Modal.Header className="d-flex justify-content-between">
               <h5 className="modal-title" id="post-modalLabel">Create {generalSettingsData?.blog_heading_text}</h5>
               <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "630px" }}>
               <div className="row">
                  {imageArray.length > 0 ?
                     <>
                        {
                           imageArray.map((elm, i) => (
                              <div key={elm + i} className="col-md-2 col-sm-12 col-lg-2">
                                 <img style={{ height: 70, width: 70, marginRight: "17px", }} loading="lazy" src={elm}
                                    alt="gallary" className="img-fluid" />
                                 <Link style={{ position: "absolute", marginTop: "-9px", marginLeft: "-30px", }}
                                    onClick={() => {
                                       let tempImageFile = imageFileArray.slice();
                                       tempImageFile.splice(i, 1)
                                       setImageFileArray(tempImageFile);
                                       //setImageFileArray(imageFileArray.splice(i, 1))
                                       //setImageArray(imageArray.splice(i, 1))
                                       let tempImage = imageArray.slice();
                                       tempImage.splice(i, 1)
                                       setImageArray(tempImage);
                                    }}>
                                    {/* <i className="icon material-symbols-outlined">cancel</i> */}
                                    <img style={{ height: 25, width: 25 }} src={cross} />
                                 </Link>
                              </div>
                           ))
                        }
                        <hr style={{ marginTop: "20px" }} />
                     </> : null
                  }
               </div>
               <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0">
                  <li className="col-md-11 mb-3">
                     <div className="rounded p-2 pointer me-3"><h5>Add image to your {generalSettingsData?.blog_heading_text} *</h5></div>
                  </li>
                  <li className="col-md-1 mb-3">
                     <div className="d-flex justify-content-center bg-soft-primary rounded p-2 pointer me-3" >
                        <label htmlFor="imageUpload1">
                           <img loading="lazy" src={small1} alt="icon" className="img-fluid" />
                        </label>
                        <input className="file-upload"
                           id="imageUpload1" type="file" accept="image/*"
                           multiple="multiple"
                           onChange={handleImageChange} />
                     </div>
                  </li>
               </ul>
               <div>
                  <hr />
                  <Row className="align-items-center">
                     <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="adTitle" className="form-label">Title *</Form.Label>
                        <Form.Control type="text" className="mb-0" placeholder="Title"
                           value={title} onChange={(e) => setTitle(e.target.value)} />
                     </Form.Group>
                     <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="startingDate" className="form-label">Publish Date *</Form.Label>
                        <Form.Control type="date" className="mb-0" placeholder="Publish Date"
                           min={new Date().toISOString().split("T")[0]}
                           value={publishDate} onChange={(e) => {
                              console.log("selectDate", e.target.value)
                              setPublishDate(e.target.value)
                           }} />
                     </Form.Group>
                     <Form.Group className="form-group col-sm-12">
                        <Form.Label htmlFor="adTitle" className="form-label">Description *</Form.Label>
                        <Form.Control type="text" className="mb-0" placeholder="Description" as="textarea" rows={4}
                           value={description} onChange={(e) => setDescription(e.target.value)} />
                     </Form.Group>
                     <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="adTitle" className="form-label">Meta Title</Form.Label>
                        <Form.Control type="text" className="mb-0" placeholder="Meta Title"
                           value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
                     </Form.Group>
                     <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="adTitle" className="form-label">Meta Tags</Form.Label>
                        <Form.Control type="text" className="mb-0" placeholder="Meta Tags"
                           value={metaTags} onChange={(e) => setMetaTags(e.target.value)} />
                     </Form.Group>
                     <Form.Group className="form-group col-sm-12">
                        <Form.Label htmlFor="adTitle" className="form-label">Meta Description</Form.Label>
                        <Form.Control type="text" className="mb-0" placeholder="Meta Description" as="textarea" rows={3}
                           value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} />
                     </Form.Group>
                     <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="adLink" className="form-label">Redirect URL</Form.Label>
                        <Form.Control type="text" className="mb-0" placeholder="Redirect URL"
                           value={redirectUrl} onChange={(e) => setRedirectUrl(e.target.value)} />
                     </Form.Group>
                     <Form.Group className="form-group col-sm-6">
                        <Form.Label htmlFor="adLink" className="form-label">Youtube Link</Form.Label>
                        <Form.Control type="text" className="mb-0" placeholder="Youtube Link"
                           value={youtubeUrl} onChange={(e) => setYoutubeUrl(e.target.value)} />
                     </Form.Group>

                  </Row>
               </div>
               <hr />
               <Button variant="primary" className="d-block w-100 mt-3"
                  onClick={() => {
                     validateBlogPost()
                  }}>{postType === "add" ? generalSettingsData?.blog_heading_text + " Post" : "Update "+ generalSettingsData?.blog_heading_text}</Button>
            </Modal.Body>
         </Modal>

      </>
   )
}

export default CreateBlogPostModal
