import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Container, Form, Nav, Row } from 'react-bootstrap'
import { apiHandler } from '../../../../api/apihandler';
import { PUBLIC_EVENT_LIST } from "../../../../api/constants";
import { InView } from "react-intersection-observer";
import { useSelector } from 'react-redux';
//profile-header
import ProfileHeader from '../../../../components/profile-header'

// images
import img6 from '../../../../assets/images/page-img/profile-bg6.jpg'
import loader from '../../../../assets/images/page-img/page-load-loader.gif'
import PublicEventCard from '../../../../components/event/publicEventCard';


const PublicEvents = () => {
   const navigate = useNavigate();
   const [upcomingEventList, setUpcomingEventList] = useState([]);
   const [pastEventList, setPastEventList] = useState([]);
   const [page, setPage] = useState(1);
   const [maxPage, setMaxPage] = useState(1);
   const [isPast, setIsPast] = useState(false);
   const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);
   let generalSettingsData = (useSelector((state) => state.setting.setting.general_settings))
   generalSettingsData = generalSettingsData ? JSON.parse(generalSettingsData) : "";

   const [isFirst, setIsFirst] = useState(true);
   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      setIsFirst(false)
   }, [isFirst]);

   useEffect(() => {
      getUpcomingEventList(1)
   }, []);

   useEffect(() => {
      if (isPast)
         getPastEventList(1)
      else
         getUpcomingEventList(1)
   }, [isPast]);

   function upcomingRefreshApi() {
      setPage(1)
      getUpcomingEventList(1)
   }

   function pastRefreshApi() {
      setPage(1)
      getPastEventList(1)
   }

   const loadMore = (inView) => {
      if (inView) {
         if (page < maxPage) {
            if (isPast)
               getPastEventList(page + 1)
            else
               getUpcomingEventList(page + 1)
            setPage(page + 1)
         }
      }
   };

   async function getUpcomingEventList(pageVal) {
      setShowMoreDataLoader(true)
      try {
         const res = await apiHandler(PUBLIC_EVENT_LIST, "POST", {
            type: "upcoming",
            page: pageVal,
            limit: 10
         })
         if (res.data?.response?.status === 200) {
            setMaxPage(res?.data?.data?.events?.meta?.last_page)
            if (res?.data?.data?.events?.meta?.total > 0) {
               if (pageVal === 1) {
                  setUpcomingEventList(res?.data?.data?.events?.data)
               } else {
                  setUpcomingEventList(upcomingEventList.concat(res?.data?.data?.events?.data))
               }
            }
         }
      } catch (error) {
         console.error(error);
      } finally {
         setShowMoreDataLoader(false)
      }
   }

   async function getPastEventList(pageVal) {
      setShowMoreDataLoader(true)
      try {
         const res = await apiHandler(PUBLIC_EVENT_LIST, "POST", {
            type: "past",
            page: pageVal,
            limit: 10
         })
         if (res.data?.response?.status === 200) {
            setMaxPage(res?.data?.data?.events?.meta?.last_page)
            if (res?.data?.data?.events?.meta?.total > 0) {
               if (pageVal === 1) {
                  setPastEventList(res?.data?.data?.events?.data)
               } else {
                  setPastEventList(pastEventList.concat(res?.data?.data?.events?.data))
               }
            }
         }
      } catch (error) {
         console.error(error);
      }
      finally {
         setShowMoreDataLoader(false)
      }
   }

   return (
      <>
         <div style={{ paddingTop: "10px", paddingBottom: "70px", backgroundColor: "#F5F7FE" }}>
            <div className="title-on-header text-center mt-5 mb-4">
               <div className="data-block">
                  <h1>{generalSettingsData?.event_heading_text}</h1>
                  <h5 style={{ cursor: "pointer" }} onClick={() => { navigate('/auth/sign-in') }}>Login to participate in the {generalSettingsData?.event_heading_text}</h5>
                  <div className="d-flex justify-content-center align-items-center mt-3">
                     <h5 className="me-2">Upcoming</h5>
                     <Form.Check className="form-switch ">
                        <Form.Check.Input type="checkbox" className={isPast ? "bg-primary" : "bg-white"} id="customSwitch01" //defaultChecked
                           style={{ height: "24px", width: "60px" }} onChange={() => setIsPast(prev => !prev)} />
                     </Form.Check>
                     <h5 className="ms-2">Past</h5>
                  </div>
               </div>
            </div>
            <div id="content-page" >
               <Container style={{ maxWidth: "98.5rem" }}>
                  <div className="d-grid gap-3 d-grid-template-1fr-19 mb-0">
                     {
                        isPast ?
                           pastEventList?.length > 0 ? pastEventList.map((elm, i) => (
                              <PublicEventCard elm={elm} getEventList={pastRefreshApi} key={elm?.id} isPast={true} />
                           )) : null :
                           upcomingEventList?.length > 0 ? upcomingEventList.map((elm, i) => (
                              <PublicEventCard elm={elm} getEventList={upcomingRefreshApi} key={elm?.id} isPast={false} />
                           )) : null
                     }
                  </div>
                  {
                     showMoreDataLoader ?
                        <div className="col-sm-12 text-center">
                           <img src={loader} alt="loader" style={{ height: "100px" }} />
                        </div> : null
                  }
                  <InView as="div" onChange={(inView, entry) => loadMore(inView)} />
               </Container>
            </div>
         </div>
      </>
   )

}

export default PublicEvents;