import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Form, Tooltip, Button, Modal } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { handleImageUpload } from "../../api/uploads";
import { FEED_MEDIA_POST, FEED_POST } from "../../api/constants";
import Moment from 'moment';
import Swal from 'sweetalert2'
import EmojiPicker from 'emoji-picker-react';
//import VideoThumbnail from 'react-video-thumbnail';
import { VideoThumbnailGenerator } from 'browser-video-thumbnail-generator';
import LoaderModal from '../loaderModal'

import cross from '../../assets/images/icon/cross.png'
import video from '../../assets/images/small/video.png'
import small07 from '../../assets/images/small/07.png'
import small08 from '../../assets/images/small/08.png'
import small09 from '../../assets/images/small/09.png'
import small1 from '../../assets/images/small/07.png'
import small2 from '../../assets/images/small/08.png'
import small3 from '../../assets/images/small/09.png'
import small4 from '../../assets/images/small/10.png'
import small5 from '../../assets/images/small/11.png'
import small6 from '../../assets/images/small/12.png'
import small7 from '../../assets/images/small/13.png'
import small8 from '../../assets/images/small/14.png'
import playButton from '../../assets/images/icon/play_button.png'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
// import state selectors
import { setIsOptimizing } from '../../store/setting/actions'

const CreatePostModal = ({ postType, refreshApi, createPostModalShow, setCreatePostModalShow, eventId }) => {
   const dispatch = useDispatch()
   let profileData = (useSelector((state) => state.setting.setting.user_data))
   profileData = profileData ? JSON.parse(profileData) : "";
   let generalSettingsData = (useSelector((state) => state.setting.setting.general_settings))
   generalSettingsData = generalSettingsData ? JSON.parse(generalSettingsData) : "";

   let optimizingShow = (useSelector((state) => state.setting.setting.is_optimizing))
   // console.log("optimizing", optimizingShow)
   const [showLoader, setShowLoader] = useState(false);
   const [loaderText, setLoaderText] = useState("");
   const [challengeExpireDate, setChallengeExpireDate] = useState("");
   const [postContent, setPostContent] = useState('');
   const [imageFileArray, setImageFileArray] = useState([]);
   const [imageArray, setImageArray] = useState([]);
   const [videoFileArray, setVideoFileArray] = useState([]);
   //const [videoArray, setVideoArray] = useState([]);
   const [videoThumbFileArray, setVideoThumbFileArray] = useState([]);
   const [videoThumbArray, setVideoThumbArray] = useState([]);
   const [showPicker, setShowPicker] = useState(false);
   const [feedFinalPostCmplt, setFeedFinalPostCmplt] = useState(false);
   // const post_modal_show = useSelector((state) => state.setting.setting.post_modal_show);
   // const create_post_type = useSelector((state) => state.setting.setting.create_post_type);
   const [isButtonDisable, setIsButtonDisable] = useState(true);

   // console.log("postType1", create_post_type)
   // console.log("postType1", postType)

   const handleClose = () => {
      setCreatePostModalShow(false)
      //setShow(false);
      setImageArray([]);
      setImageFileArray([])
      //setVideoArray([]);
      setVideoFileArray([])
      setVideoThumbArray([])
      setVideoThumbFileArray([])
      setPostContent("")
      setChallengeExpireDate("")
      // dispatch(setPostModalShow(false))
   }

   // const handleShow = () => {
   //    setImageArray([]);
   //    setImageFileArray([])
   //    //setVideoArray([]);
   //    setVideoFileArray([])
   //    setVideoThumbArray([])
   //    setVideoThumbFileArray([])
   //    setPostContent("")
   //    setChallengeExpireDate("")
   //    dispatch(setCreatePostType(postType))
   //    dispatch(setPostModalShow(true))
   // }

   const deleteVideo = (index) => {
      videoThumbArray.splice(index, 1)
      videoThumbFileArray.splice(index, 1)
      videoFileArray.splice(index, 1)
   }

   const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
         const reader = new FileReader();
         reader.onloadend = async () => {
            //console.log("uploaded", file)
            setImageFileArray([...imageFileArray, file])
            setImageArray([...imageArray, reader.result])
         };
         reader.readAsDataURL(file);
      }
   };

   const handleVideoChange = (event) => {
      setShowLoader(true)
      const file = event.target.files[0];
      if (file) {
         const reader = new FileReader();
         reader.onloadend = async () => {
            await handleGenerateThumbnail(reader.result)
            setVideoFileArray([...videoFileArray, file])
            //setVideoArray([...videoArray, reader.result])
         };
         reader.readAsDataURL(file);
      }
   };

   async function handleGenerateThumbnail(videoUrl) {
      try {
         // const generator = new VideoThumbnailGenerator(videoUrl);
         // generator.getThumbnail()
         //    .then((thumbnail) => {
         //       fetch(thumbnail?.thumbnail)
         //          .then((res) => res.blob())
         //          .then((blob) => {
         //             const file = new File([blob], `${+new Date()}.jpg`, { type: 'image/jpeg' });
         //             setVideoThumbFileArray([...videoThumbFileArray, file])
         //             setVideoThumbArray([...videoThumbArray, thumbnail?.thumbnail])
         //             setShowLoader(false)
         //          })
         //          .catch((error) => {
         //             setShowLoader(false)
         //          });
         //    });
         const generator = new VideoThumbnailGenerator(videoUrl);
         generator.getThumbnail()
            .then((thumbnail) => {
               fetch(thumbnail?.thumbnail)
                  .then((res) => res.blob())
                  .then((blob) => {
                     const img = new Image();
                     img.src = URL.createObjectURL(blob);
                     img.onload = () => {
                        const canvas = document.createElement('canvas');
                        canvas.width = 500;
                        canvas.height = 300;
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, 500, 300);

                        canvas.toBlob((resizedBlob) => {
                           const file = new File([resizedBlob], `${+new Date()}.jpg`, { type: 'image/jpeg' });
                           setVideoThumbFileArray([...videoThumbFileArray, file]);
                           setVideoThumbArray([...videoThumbArray, URL.createObjectURL(resizedBlob)]);
                           setShowLoader(false);
                        }, 'image/jpeg');
                     };
                  })
                  .catch((error) => {
                     setShowLoader(false);
                     console.error('Error fetching or resizing image:', error);
                  });
            });
      } catch (error) {
         setShowLoader(false)
         Swal.fire({
            title: 'Error',
            text: "This video file is not support",
            icon: 'error',
            confirmButtonText: 'Okay'
         })
      }
   };

   if (optimizingShow && feedFinalPostCmplt) {
      setShowLoader(false)
      refreshApi()
      handleClose()
      //setShow(false)
      Swal.fire({
         title: 'Success',
         text: 'Your post created successfully',
         icon: 'success',
         confirmButtonText: 'Okay'
      })
      dispatch(setIsOptimizing(false));
   }

   useEffect(() => {

   }, [])

   function handlePostSubmit() {
      if (postContent != "" || imageArray.length > 0 || videoThumbArray.length > 0) {
         if (postType === "2" || postType === 2 || postType === "3" || postType === 3) {
            if (challengeExpireDate != "") {
               var isCmplt = 0
               if (imageArray.length > 0 || videoThumbArray.length > 0) {
                  isCmplt = 0
               } else {
                  isCmplt = 1
               }
               setShowLoader(true)
               getFeedPost(isCmplt)
            } else {
               Swal.fire({
                  title: 'Warning!',
                  text: 'Please select challenge expire date!',
                  icon: 'warning',
                  confirmButtonText: 'Okay'
               })
            }
         } else {
            var isCmplt = 0
            if (imageArray.length > 0 || videoThumbArray.length > 0) {
               isCmplt = 0
            } else {
               isCmplt = 1
            }
            setShowLoader(true)
            getFeedPost(isCmplt)
         }
      } else {
         Swal.fire({
            title: 'Warning!',
            text: 'Please enter post content or select post file!',
            icon: 'warning',
            confirmButtonText: 'Okay'
         })
      }
   };

   async function getFeedPost(isCmplt) {
      try {
         const res = await apiHandler(FEED_POST, "POST", (postType === "7" || postType === 7) ? {
            content: postContent,
            type: postType,
            is_completed: isCmplt,
            expire_at: challengeExpireDate,
            class_id: eventId
         } : {
            content: postContent,
            type: postType,
            is_completed: isCmplt,
            expire_at: challengeExpireDate,
            event_id: eventId
         })
         if (res.data?.response?.status === 200) {
            if (isCmplt === 1) {
               refreshApi()
               handleClose()
               setShowLoader(false)
               Swal.fire({
                  title: 'Success',
                  text: 'Your post created successfully',
                  icon: 'success',
                  confirmButtonText: 'Okay'
               })
            } else {
               //setPostId(res?.data?.data?.feed?.id)
               handleFeedPostSubmit(res?.data?.data?.feed?.id)
            }
         } else {
            Swal.fire({
               title: 'Error',
               text: res?.response?.data?.data?.message,
               icon: 'error',
               confirmButtonText: 'Okay'
            })
            //navigate('/auth/sign-in')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      }
      finally {
      }
   }

   async function handleFeedPostSubmit(post_id) {
      if (imageFileArray.length > 0) {
         for (let elm of imageFileArray) {
            const url = await handleImageUpload(elm,
               `${postType === "2" || postType === 2 || postType === "3" || postType === 3 || postType === "4" || postType === 4 ?
                  "feedImages/" : "feedImages/"}${Date.now()}`, "image/jpeg")
            //console.log("uploaded", url)
            getFeedMediaPost(url, "", "0", post_id)
         }
      }
      if (videoFileArray.length > 0) {
         //for (let elm of videoFileArray) {
         for (let i = 0; i < videoFileArray?.length; i++) {
            const url = await handleImageUpload(videoFileArray[i], 
               `${postType === "2" || postType === 2 || postType === "3" || postType === 3 || postType === "4" || postType === 4 ?
                  "feedVideos/":"feedVideos/"}${Date.now()}`, "video/*")
            const thumb = await handleImageUpload(videoThumbFileArray[i], `feedVideosThumb/${Date.now()}`, "image/jpeg")
            //console.log("uploaded", url)
            getFeedMediaPost(url, thumb, "1", post_id)
         }
      }
      getFeedFinalPost(post_id)
   };

   async function getFeedFinalPost(post_id) {
      try {
         const res = await apiHandler(FEED_POST, "POST", (postType === "7" || postType === 7) ? {
            content: postContent,
            type: postType,
            is_completed: "1",
            expire_at: challengeExpireDate,
            post_id: post_id,
            class_id: eventId
         } : {
            content: postContent,
            type: postType,
            is_completed: "1",
            expire_at: challengeExpireDate,
            post_id: post_id,
            event_id: eventId
         })
         if (res.data?.response?.status === 200) {
            setFeedFinalPostCmplt(true)
            setLoaderText("Optimizing...")
            dispatch(setIsOptimizing(false));
         } else {
            Swal.fire({
               title: 'Error',
               text: res?.response?.data?.data?.message,
               icon: 'error',
               confirmButtonText: 'Okay'
            })
            //navigate('/auth/sign-in')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      } finally {
      }
   }

   async function getFeedMediaPost(awsPath, awsThumb, mediaType, post_id) {
      try {
         const res = await apiHandler(FEED_MEDIA_POST, "POST", {
            post_id: post_id,
            path: awsPath,
            media_type: mediaType,
            thumb: awsThumb
         })
         if (res.data?.response?.status === 200) {
            // setShow(false)
            // Swal.fire({
            //    title: 'Success',
            //    text: 'Your post created successfully',
            //    icon: 'success',
            //    confirmButtonText: 'Okay'
            // })
         } else {
            Swal.fire({
               title: 'Error',
               text: res?.response?.data?.data?.message,
               icon: 'error',
               confirmButtonText: 'Okay'
            })
            //navigate('/auth/sign-in')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      }
      finally {
      }
   }

   return (
      <>
         <LoaderModal showLoader={showLoader} loaderData={loaderText} />
         {/* <Card id="post-modal-data" >
            <div className="card-header d-flex justify-content-between">
               <div className="header-title">
                  <h4 className="card-title">Create
                     {postType === "2" || postType === 2 ? " a Challenge" :
                        postType === "3" || postType === 3 ? " a Challenge" :
                           postType === "4" || postType === 4 ? " Sponsor Challenge" :
                              postType === "5" || postType === 5 ? " Event Post" :
                                 postType === "6" || postType === 6 ? " Announcement Post" : " Post"}</h4>
               </div>
            </div>
            <Card.Body>
               <div className="d-flex align-items-center">
                  <div className="user-img">
                     <img loading="lazy" src={profileData?.user_setting?.photo ? profileData?.user_setting?.photo :
                        "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="userimg" className="avatar-60 rounded-circle" />
                  </div>
                  <form className="post-text ms-3 w-100 " onClick={handleShow}>
                     <input type="text" className="form-control rounded" placeholder="Write something here..." style={{ border: "none" }} />
                  </form>
               </div>
               <hr />
               <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                  <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3 mb-md-0 mb-2"><img loading="lazy" src={small07} alt="icon" className="img-fluid me-2" /> Photo/Video</li>
                  <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3 mb-md-0 mb-2"><img loading="lazy" src={small08} alt="icon" className="img-fluid me-2" /> Tag Friend</li>
                  <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3"><img loading="lazy" src={small09} alt="icon" className="img-fluid me-2" /> Feeling/Activity</li>
                  <li className="bg-soft-primary rounded p-2 pointer text-center">
                     <div className="card-header-toolbar d-flex align-items-center">
                        <Dropdown>
                           <Dropdown.Toggle as={CustomToggle} id="post-option"  >
                              <span className="material-symbols-outlined">
                                 more_horiz
                              </span>
                           </Dropdown.Toggle>
                           <Dropdown.Menu className=" dropdown-menu-right" aria-labelledby="post-option" >
                              <Dropdown.Item onClick={handleShow} href="#" >Check in</Dropdown.Item>
                              <Dropdown.Item onClick={handleShow} href="#" >Live Video</Dropdown.Item>
                              <Dropdown.Item onClick={handleShow} href="#" >Gif</Dropdown.Item>
                              <Dropdown.Item onClick={handleShow} href="#" >Watch Party</Dropdown.Item>
                              <Dropdown.Item onClick={handleShow} href="#" >Play with Friend</Dropdown.Item>
                           </Dropdown.Menu>
                        </Dropdown>
                     </div>
                  </li>
               </ul>
            </Card.Body>
            </Card> */}
         <Modal show={createPostModalShow} onHide={handleClose} size="lg" style={{ marginTop: 100 }}>
            <Modal.Header className="d-flex justify-content-between">
               <h5 className="modal-title" id="post-modalLabel">Create
                  {postType === "1" || postType === 1 ? " Stories" :
                     postType === "2" || postType === 2 ? " a Challenge" :
                        postType === "3" || postType === 3 ? " a Challenge" :
                           postType === "4" || postType === 4 ? " Sponsor Challenge" :
                              postType === "5" || postType === 5 ? " " + generalSettingsData?.event_heading_text + " Post" :
                                 postType === "6" || postType === 6 ? " Announcement Post" : " Post"}</h5>
               <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
            </Modal.Header>
            <Modal.Body>
               <div className="d-flex align-items-center">
                  <div className="user-img">
                     <img style={{ height: 60, width: 60 }} loading="lazy" src={profileData?.user_setting?.photo ? profileData?.user_setting?.photo :
                        "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="userimg" className="avatar-60 rounded-circle img-fluid" />
                  </div>
                  <form className="post-text ms-3 w-100" action="">
                     <input type="text" className="form-control rounded" placeholder="Write something here..." style={{ border: "none" }}
                        value={postContent} onChange={(e) => { setPostContent(e.target.value) }} />
                  </form>
               </div>
               <hr />
               <div className="row">
                  {imageArray.length > 0 || videoThumbArray.length > 0 ?
                     <>
                        {
                           imageArray.map((elm, i) => (
                              <div key={elm + i} className="col-md-2 col-sm-12 col-lg-2">
                                 <img style={{ height: 70, width: 70, marginRight: "17px", }} loading="lazy" src={elm}
                                    alt="gallary" className="img-fluid" />
                                 <Link style={{ position: "absolute", marginTop: "-9px", marginLeft: "-30px", }}
                                    onClick={() => {
                                       let tempImageFile = imageFileArray.slice();
                                       tempImageFile.splice(i, 1)
                                       setImageFileArray(tempImageFile);
                                       //setImageFileArray(imageFileArray.splice(i, 1))
                                       //setImageArray(imageArray.splice(i, 1))
                                       let tempImage = imageArray.slice();
                                       tempImage.splice(i, 1)
                                       setImageArray(tempImage);
                                    }}>
                                    {/* <i className="icon material-symbols-outlined">cancel</i> */}
                                    <img style={{ height: 25, width: 25 }} src={cross} />
                                 </Link>
                              </div>
                           ))
                        }
                        {
                           videoThumbArray.map((elm, i) => (
                              <div key={"key" + i} className="col-md-2 col-sm-12 col-lg-2" >
                                 <div style={{ width: 70, height: 70 }} className="img-fluid">
                                    <img style={{ height: 70, width: 70, marginRight: "17px", }} loading="lazy" src={videoThumbArray[i]}
                                       alt="gallary" className="img-fluid" />
                                    {/* <VideoThumbnail
                                          width={70}
                                          height={70}
                                          videoUrl={elm} /> */}
                                 </div>

                                 <Link style={{ position: "absolute", marginTop: "-79px", marginLeft: "55px", zIndex: 1000 }}
                                    onClick={() => {
                                       // videoThumbArray.splice(index, 1)
                                       // videoThumbFileArray.splice(index, 1)
                                       // videoFileArray.splice(index, 1)

                                       let tempVideoThumb = videoThumbArray.slice();
                                       tempVideoThumb.splice(i, 1)
                                       setVideoThumbArray(tempVideoThumb);

                                       let tempVideoThumbFile = videoThumbFileArray.slice();
                                       tempVideoThumbFile.splice(i, 1)
                                       setVideoThumbFileArray(tempVideoThumbFile);

                                       let tempVideoFile = videoFileArray.slice();
                                       tempVideoFile.splice(i, 1)
                                       setVideoFileArray(tempVideoFile);
                                    }}>
                                    <img style={{ height: 25, width: 25 }} src={cross} />
                                 </Link>
                                 <Link style={{ position: "absolute", marginTop: "-48px", marginLeft: "26px", }}>
                                    <img style={{ height: 25, width: 25 }} src={playButton} />
                                 </Link>
                              </div>
                           ))
                        }
                        <hr style={{ marginTop: "20px" }} />
                     </> : null
                  }
               </div>
               <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0">
                  <li className="col-md-9 mb-3">
                     <div className="rounded p-2 pointer me-3"><h5>Add to your post</h5></div>
                  </li>
                  <li className="col-md-1 mb-3">
                     <div className="d-flex justify-content-center bg-soft-primary rounded p-2 pointer me-3" >
                        <label htmlFor="imageUpload1">
                           <img loading="lazy" src={small1} alt="icon" className="img-fluid" />
                        </label>
                        <input className="file-upload"
                           id="imageUpload1" type="file" accept="image/*"
                           multiple="multiple"
                           onChange={handleImageChange} />
                     </div>
                  </li>
                  <li className="col-md-1 mb-3">
                     <div className="d-flex justify-content-center bg-soft-primary rounded p-2 pointer me-3">
                        <label htmlFor="videoUpload1" style={{ maxHeight: "25px" }}>
                           <img loading="lazy" src={video} alt="icon" className="img-fluid" style={{ maxHeight: "28px" }} />
                        </label>
                        <input className="file-upload"
                           id="videoUpload1" type="file" accept="video/*"
                           multiple="multiple"
                           onChange={handleVideoChange} />
                     </div>
                  </li>
                  {/* <li className="col-md-1 mb-3">
                        <div className="bg-soft-primary rounded p-2 pointer me-3"><Link to="#"></Link><img loading="lazy" src={small2} alt="icon" className="img-fluid" /></div>
                     </li> */}
                  <li className="col-md-1 mb-3">
                     <div className="d-flex justify-content-center bg-soft-primary rounded p-2 pointer me-3" onClick={() => setShowPicker((val) => !val)}>
                        <Link to="#"></Link><img loading="lazy" src={small3} alt="icon" className="img-fluid" />
                     </div>
                  </li>
                  {/* <li className="col-md-1 mb-3">
                        <div className="bg-soft-primary rounded p-2 pointer me-3"><Link to="#"></Link><img loading="lazy" src={small6} alt="icon" className="img-fluid" /></div>
                     </li> */}
               </ul>
               {showPicker ?
                  <EmojiPicker pickerStyle={{ width: "100%" }} onEmojiClick={(emojiObject) => setPostContent((prevMsg) => prevMsg + emojiObject.emoji)} /> : null
               }
               {
                  postType === "2" || postType === 2 || postType === "3" || postType === 3 ?
                     <div>
                        <hr />
                        <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0">
                           <li className="col-md-7 mb-1">
                              <h5>Select Expiry Date</h5>
                           </li>
                           <li className="col-md-5 mb-1">
                              <Form.Group className="form-group">
                                 <Form.Control type="date" className="mb-0" id="dob" placeholder="Date of Birth"
                                    min={new Date().toISOString().split("T")[0]}
                                    //defaultValue={setDOB(date_variable)}
                                    value={challengeExpireDate} onChange={(e) => setChallengeExpireDate(e.target.value)} />
                              </Form.Group>
                           </li>
                        </ul>
                     </div> : null
               }

               <hr />
               <Button variant="primary" className="d-block w-100 mt-3"
                  onClick={() => {
                     handlePostSubmit()
                  }}>Create
                  {postType === "1" || postType === 1 ? " a Stories" :
                     postType === "2" || postType === 2 ? " a Challenge" :
                        postType === "3" || postType === 3 ? " a Challenge" :
                           postType === "4" || postType === 4 ? " Sponsor Challenge" :
                              postType === "5" || postType === 5 ? " " + generalSettingsData?.event_heading_text + " Post" :
                                 postType === "6" || postType === 6 ? " Announcement Post" : "a Post"}</Button>
            </Modal.Body>
         </Modal>

      </>
   )
}

export default CreatePostModal
