import React, { useState, useEffect } from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import DOMPurify from "isomorphic-dompurify";
import { apiHandler } from '../../api/apihandler';
import { CMS_DETAILS } from "../../api/constants";

// images
import loader from '../../assets/images/page-img/page-load-loader.gif'

const AboutUs = () => {
    const [cmsDetailsData, setCMSDetailsData] = useState('');
    const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);

    useEffect(() => {
        getCMSDetails("about")
    }, [])

    async function getCMSDetails(slug) {
        setShowMoreDataLoader(true)
        try {
            const res = await apiHandler(CMS_DETAILS, "POST", {
                slug: slug
            })
            if (res.data?.response?.status === 200) {
                setCMSDetailsData(res?.data?.data?.cmsPage)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowMoreDataLoader(false)
        }
    }

    return (
        <>
            <Container>
                <Container fluid style={{ marginTop: "20px" }}>
                    <Row>
                        <Col lg="12">
                            {
                                cmsDetailsData?.cms_page_body?.length > 0 ? cmsDetailsData?.cms_page_body?.map((elm, i) => (
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="header-title">
                                                <h3 className="card-title">{elm?.heading}</h3>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(elm?.description) }} />
                                        </Card.Body>
                                    </Card>
                                )) : null
                            }
                        </Col>
                        {
                            showMoreDataLoader ?
                                <div className="col-sm-12 text-center">
                                    <img src={loader} alt="loader" style={{ height: "100px" }} />
                                </div> : null
                        }
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default AboutUs
