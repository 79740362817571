import React, { useState, useEffect } from 'react'
import { Row, Col, Image, Container, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import Moment from 'moment';
import { apiHandler } from '../../../api/apihandler';
import { BLOG_CATEGORY, BLOG_LIST, BLOG_TAG_LIST } from '../../../api/constants'
import { InView } from "react-intersection-observer";

// Redux Selector / Action
import { useSelector } from 'react-redux';

import loader from '../../../assets/images/page-img/page-load-loader.gif'
import BlogCard from '../../../components/blog/blogCard';
import NoDataFound from '../../../components/noDataFound';

import blog3 from '../../../assets/images/blog/03.jpg'
import blog4 from '../../../assets/images/blog/04.jpg'
import blog5 from '../../../assets/images/blog/05.jpg'
import blog6 from '../../../assets/images/blog/06.jpg'
import blog7 from '../../../assets/images/blog/07.jpg'
import blog8 from '../../../assets/images/blog/08.jpg'


const BlogList = () => {
   const profileData = JSON.parse(useSelector((state) => state.setting.setting.user_data));
   let generalSettingsData = (useSelector((state) => state.setting.setting.general_settings))
   generalSettingsData = generalSettingsData ? JSON.parse(generalSettingsData) : "";
   const navigate = useNavigate();
   const [blogList, setBlogList] = useState([]);
   const [blogCategoryList, setBlogCategoryList] = useState([]);
   const [tagList, setTagList] = useState([]);
   const [page, setPage] = useState(1);
   const [maxPage, setMaxPage] = useState(1);
   const [categoryId, setCategoryId] = useState(0);
   const [tagId, setTagId] = useState("");
   const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);
   const dummyBlogCategoryData = {
      "id": 0,
      "slug": "",
      "parent_id": null,
      "name": "All",
      "meta_title": null,
      "meta_tag": null,
      "meta_description": null,
      "created_at": null,
      "updated_at": null,
      "description": null,
      "post_last_date": null
   }

   const [isFirst, setIsFirst] = useState(true);
   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      setIsFirst(false)
   }, [isFirst]);

   useEffect(() => {
      if (localStorage.getItem("uzoneAuthToken")) {
         getBlogList(1, categoryId, tagId)
         getBlogCategoryList()
         getTagList()
      } else {
         navigate('/')
      }
   }, []);

   function refreshApi() {
      setPage(1)
      getBlogList(1, categoryId, tagId)
   }

   const loadMore = (inView) => {
      if (inView) {
         if (page < maxPage) {
            getBlogList(page + 1, categoryId, tagId)
            setPage(page + 1)
         }
      }
   };

   async function getBlogList(pageVal, categoryId, tagId) {
      setShowMoreDataLoader(true)
      try {
         const res = await apiHandler(BLOG_LIST, "POST", {
            // user_id: profileData?.id,
            category_id: categoryId,
            tag_id: tagId,
            page: pageVal,
            limit: 10
         })
         if (res.data?.response?.status === 200) {
            setMaxPage(res?.data?.data?.blogs?.meta?.last_page)
            if (res?.data?.data?.blogs?.meta?.total > 0) {
               if (pageVal === 1) {
                  setBlogList(res?.data?.data?.blogs?.data)
               } else {
                  setBlogList(blogList.concat(res?.data?.data?.blogs?.data))
               }
            }
         }
      } catch (error) {
         console.error(error);
      }
      finally {
         setShowMoreDataLoader(false)
      }
   }

   async function getBlogCategoryList() {
      try {
         const res = await apiHandler(BLOG_CATEGORY, "POST", {
            page: 1,
            limit: 100
         })
         if (res.data?.response?.status === 200) {
            // setBlogCategoryList(res?.data?.data?.blogcats?.data)
            setBlogCategoryList([dummyBlogCategoryData, ...res?.data?.data?.blogcats?.data])
         }
      } catch (error) {
         console.error(error);
      }
      finally {
      }
   }

   async function getTagList() {
      try {
         const res = await apiHandler(BLOG_TAG_LIST, "POST", {
            page: 1,
            limit: 100
         })
         if (res.data?.response?.status === 200) {
            setTagList(res?.data?.data?.blogtags?.data)
         }
      } catch (error) {
         console.error(error);
      }
      finally {
      }
   }

   return (
      <>
         <div id='content-page' className='content-page'>
            <Container>
               <Row>
                  <Col lg={8}>
                     <div>
                        {
                           blogList?.length > 0 ? blogList.map((elm, i) => (
                              <BlogCard elm={elm} getBlogList={refreshApi} key={elm?.id} index={i} />
                           )) : <NoDataFound />
                        }
                     </div>
                     {
                        showMoreDataLoader ?
                           <div className="col-sm-12 text-center">
                              <img src={loader} alt="loader" style={{ height: "100px" }} />
                           </div> : null
                     }
                     <InView as="div" onChange={(inView, entry) => loadMore(inView)} />
                  </Col>
                  <Col lg={4}>
                     <div>
                        <Card className="card-block card-stretch card-height blog-post">
                           <Card.Header className="d-flex justify-content-between">
                              <div className="header-title">
                                 <h4 className="card-title">{generalSettingsData?.blog_heading_text} Category</h4>
                              </div>
                           </Card.Header>
                           <Card.Body>
                              {
                                 blogCategoryList.map((elm, i) => (
                                    <>
                                       {
                                          elm?.id === categoryId ?
                                             <Link className="mb-1 me-2 w-100" style={{ fontSize: "14px" }} key={elm?.id}
                                                onClick={() => {
                                                   getBlogList(1, elm?.id, "")
                                                   setCategoryId(elm?.id)
                                                   setTagId("")
                                                   setPage(1)
                                                }}>{elm?.name}
                                             </Link> :
                                             <h6 className=" mb-1 me-2 w-100" style={{ cursor: "pointer" }} key={elm?.id}
                                                onClick={() => {
                                                   getBlogList(1, elm?.id, "")
                                                   setCategoryId(elm?.id)
                                                   setTagId("")
                                                   setPage(1)
                                                }}>{elm?.name}
                                             </h6>
                                       }
                                    </>
                                 ))
                              }
                           </Card.Body>
                        </Card>
                        {
                           tagList?.length > 0 ?
                              <Card className="card-block card-stretch card-height blog-post">
                                 <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                       <h4 className="card-title">Tag List</h4>
                                    </div>
                                 </Card.Header>
                                 <Card.Body>
                                    {
                                       tagList.map((elm, i) => (
                                          <>
                                             {
                                                elm?.id === tagId ?
                                                   <Button variant="primary" className="rounded-pill mb-1 me-2" key={elm?.id}
                                                      onClick={() => {
                                                         getBlogList(1, "", elm?.id)
                                                         setTagId(elm?.id)
                                                         setCategoryId("")
                                                         setPage(1)
                                                      }}>{elm?.slug}
                                                   </Button> :
                                                   <Button variant="secondary" className="rounded-pill mb-1 me-2" key={elm?.id}
                                                      onClick={() => {
                                                         getBlogList(1, "", elm?.id)
                                                         setTagId(elm?.id)
                                                         setCategoryId("")
                                                         setPage(1)
                                                      }}>{elm?.slug}
                                                   </Button>
                                             }
                                          </>
                                       ))
                                    }
                                 </Card.Body>
                              </Card> : null
                        }

                     </div>
                  </Col>
               </Row>
            </Container>
         </div>
      </>
   )
}

export default BlogList