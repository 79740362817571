import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import ShareOffcanvas from '../share-offcanvas'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../api/apihandler';
import { AD_DELETE, FEED_DELETE } from "../../api/constants";
import Moment from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import LoaderModal from '../loaderModal'

// images
import playButton from '../../assets/images/icon/play_button.png'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import AdEditCard from './adEditCard'

// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;


const AdCard = ({ elm, getAdsList }) => {
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";

    const [show, setShow] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showFlagModal, setFlagModalShow] = useState(false);
    const navigate = useNavigate();
    const [feedMediaList, setFeedMediaList] = useState([]);
    const [feedMediaTypeList, setFeedMediaTypeList] = useState([]);
    const [showEditPostModal, setShowEditPostModal] = useState(false);
    const handleClose = () => setShowEditPostModal(false);
    let adPostId = null;

    const [imageController, setImageController] = useState({
        toggler: false,
        slide: 1
    });
    function imageOnSlide(number) {
        setImageController({
            toggler: !imageController.toggler,
            slide: number
        });
    }

    // async function createMedia() {
    //     elm?.user_post_images.map((image, i) => {
    //         if (!feedMediaList.includes(image?.image_path)) {
    //             feedMediaList.push(image?.image_path)
    //             setFeedMediaList(feedMediaList)
    //             if (image?.media_type === 1) {
    //                 feedMediaTypeList.push("video")
    //                 setFeedMediaTypeList(feedMediaTypeList)
    //             } else {
    //                 feedMediaTypeList.push("image")
    //                 setFeedMediaTypeList(feedMediaTypeList)

    //             }
    //         }
    //     })
    // }

    useEffect(() => {
        // createMedia()
    }, [])


    async function getAdDelete(post_id) {
        if (localStorage.getItem("uzoneAuthToken")) {
            try {
                const res = await apiHandler(AD_DELETE, "DELETE", {
                    post_id: post_id
                })
                if (res.data?.response?.status === 200) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Your Advertise deleted successfully',
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    })
                    getAdsList()
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: res?.response?.data?.data?.message,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                }
            } catch (error) {
                console.error(error);
            } finally {
            }
        } else {
            navigate('/')
        }
    }

    return (
        <>
            <LoaderModal showLoader={showLoader} />
            {
                feedMediaList?.length > 0 ?
                    <FsLightbox
                        toggler={imageController.toggler}
                        sources={feedMediaList}
                        slide={imageController.slide}
                        types={feedMediaTypeList}
                    />
                    : null
            }

            <Card>
                <Card.Body>
                    <div className="post-item">
                        <div className="user-post-data pb-3">
                            <div className="d-flex justify-content-between">
                                <div className="me-3">
                                    <img loading="lazy" className="rounded-circle  avatar-45"
                                        src={elm?.user?.user_setting?.photo ? elm?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                </div>
                                <div className="w-100">
                                    <div className="d-flex justify-content-between flex-wrap">
                                        <div>
                                            <h5 className="mb-0 d-inline-block"><Link to={profileData?.user_type === 4 ? "#" : `/dashboard/app/friend-profile/${elm?.user?.slug}`}>{elm?.user?.first_name + " " + elm?.user?.last_name}</Link></h5>
                                            <p className="mb-0">{Moment(elm?.created_at).format('DD MMMM YYYY')}</p>
                                        </div>
                                        {
                                            (Moment(new Date())) <= (Moment(elm?.user_post_ad?.ad_end_date)) ?
                                                <div className="card-post-toolbar">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="bg-transparent">
                                                            <span className="material-symbols-outlined">
                                                                more_horiz
                                                            </span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className=" m-0 p-0">
                                                            {
                                                                Moment(elm?.user_post_ad?.ad_start_date) > new Date ?
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top"
                                                                            onClick={() =>
                                                                                Swal.fire({
                                                                                    title: 'Warning!',
                                                                                    text: 'Are you sure, want to delete this feed?',
                                                                                    icon: 'warning',
                                                                                    showCancelButton: true,
                                                                                    showConfirmButton: true,
                                                                                    cancelButtonText: 'Cancel',
                                                                                    confirmButtonText: 'Confirm',
                                                                                    confirmButtonColor: "#5c518b"
                                                                                }).then((result) => {
                                                                                    if (result.isConfirmed) {
                                                                                        getAdDelete(elm?.id)
                                                                                    }
                                                                                })}>
                                                                            <i className="ri-delete-bin-7-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Delete</h6>
                                                                                <p className="mb-0">Remove this Post from your profile</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item> : null
                                                            }
                                                            <Dropdown.Item className=" p-3" to="#">
                                                                <div className="d-flex align-items-top" onClick={() => {
                                                                    adPostId = elm?.id
                                                                    setShowEditPostModal(true)
                                                                }}>
                                                                    <i className="ri-edit-2-fill h4"></i>
                                                                    <div className="data ms-2">
                                                                        <h6>Edit Post</h6>
                                                                        <p className="mb-0">Update your post and saved items</p>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            elm?.user_post_ad?.title ?
                                <div className="mt-1">
                                    <p>{elm?.user_post_ad?.title}</p>
                                </div> : null
                        }
                        <div className="user-post">
                            <Link to={`/dashboards/app/ads-details/${elm?.id}`}>
                                <ResponsiveMasonry
                                    columnsCountBreakPoints={{ 350: 1, 750: 1, 900: 1 }}>
                                    <Masonry columnsCount={3} gutter="10px">
                                        {elm?.user_post_images.map((image, i) => (
                                            image?.media_type === 1 ?
                                                <div key={image?.id} onClick={() => {
                                                    //imageOnSlide(i + 1)
                                                }}>
                                                    <div className="img-fluid" style={{ position: "relative" }} >
                                                        <img loading="lazy"
                                                            src={image?.thumb} alt="gallary" className="img-fluid" />
                                                    </div>
                                                    <Link style={{ position: "absolute", left: "47%", top: "47%", zIndex: 1 }}>
                                                        <img style={{ height: 50, width: 50 }} src={playButton} />
                                                    </Link>
                                                </div> :
                                                <img key={image?.id} loading="lazy" src={image?.image_path}
                                                    alt="post" className="img-fluid w-100"
                                                    onClick={() => {
                                                        //imageOnSlide(i + 1)
                                                    }} />
                                        ))}
                                    </Masonry>
                                </ResponsiveMasonry>
                            </Link>
                        </div>

                        <div className="comment-area mt-4">
                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                <div className="like-block position-relative d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            {/* <h6 className="mb-0 d-inline-block">
                                                {
                                                    elm?.is_like === 1 ?
                                                        elm?.like_count > 1 ?
                                                            <Link to="#"
                                                                onMouseEnter={() => setShowLikesList(true)}
                                                                onMouseLeave={() => setShowLikesList(false)}>
                                                                You and {((elm?.like_count) - 1) + " "} others
                                                            </Link> :
                                                            <Link to="#"
                                                                onMouseEnter={() => setShowLikesList(true)}
                                                                onMouseLeave={() => setShowLikesList(false)}>
                                                                You like
                                                            </Link> :
                                                        <Link to="#" style={{ marginLeft: "6px" }}
                                                            onMouseEnter={() => setShowLikesList(true)}
                                                            onMouseLeave={() => setShowLikesList(false)}>
                                                            {elm?.like_count + " "}like
                                                        </Link>
                                                }
                                            </h6> */}
                                            <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}>
                                                <Link to={`/dashboards/app/ads-details/${elm?.id}`}>
                                                    {elm?.overall_view ? elm?.overall_view : 0 + " "}Views
                                                </Link>
                                            </h6>
                                            <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}>
                                                <Link to={`/dashboards/app/ads-details/${elm?.id}`} >
                                                    {elm?.overall_click ? elm?.overall_click : 0 + " "}Clicks
                                                </Link>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </Card.Body>
            </Card>

            {
                showEditPostModal ?
                    <AdEditCard adPostId={adPostId} showEditPostModal={showEditPostModal}
                        handleClose={handleClose} refreshApi={getAdsList} /> : null
            }
        </>
    )
}

export default AdCard
