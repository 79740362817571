import React, { useState, useEffect } from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import DOMPurify from "isomorphic-dompurify";
import { apiHandler } from '../../api/apihandler';
import { CMS_DETAILS } from "../../api/constants";

// images
import loader from '../../assets/images/page-img/page-load-loader.gif'

const TermsofService = () => {
    const [cmsDetailsData, setCMSDetailsData] = useState('');
    const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);

    useEffect(() => {
        getCMSDetails("terms-of-use")
    }, [])

    async function getCMSDetails(slug) {
        setShowMoreDataLoader(true)
        try {
            const res = await apiHandler(CMS_DETAILS, "POST", {
                slug: slug
            })
            if (res.data?.response?.status === 200) {
                setCMSDetailsData(res?.data?.data?.cmsPage)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowMoreDataLoader(false)
        }
    }

    return (
        <>
            <Container style={{ marginTop: "20px" }}>
                <Row>
                    <Col lg="12">
                        {
                            cmsDetailsData?.cms_page_body?.length > 0 ? cmsDetailsData?.cms_page_body?.map((elm, i) => (
                                <Card>
                                    <Card.Header className="d-flex justify-content-between">
                                        <div className="header-title">
                                            <h3 className="card-title">{elm?.heading}</h3>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(elm?.description) }} />
                                    </Card.Body>
                                </Card>
                            )) : null
                        }
                        {/* <div className="iq-accordion career-style faq-style  ">
                            <Card className="iq-accordion-block accordion-active p-0">
                                <Card.Header className="active-faq clearfix d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Lorem ipsum dolor sit</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body className="accordion-details">
                                    <p className="mb-0 p-0">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </p>
                                </Card.Body>
                            </Card>
                        </div> */}
                    </Col>
                    {
                        showMoreDataLoader ?
                            <div className="col-sm-12 text-center">
                                <img src={loader} alt="loader" style={{ height: "100px" }} />
                            </div> : null
                    }
                </Row>
            </Container>
        </>
    )
}

export default TermsofService
