import React, { useState, useEffect } from 'react'
import {
  Dropdown,
  Nav,
  Form,
  Card,
  Container,
  Image,
  Modal,
  Accordion,
  Button,
} from "react-bootstrap";
import { Link, useNavigate, Route, useLocation } from 'react-router-dom'
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { apiHandler } from '../../../../api/apihandler';
import { GENERAL_SETTINGS, MY_PARTICIPATED_EVENT, NOTIFICATION_COUNT, PROFILE_DETAILS, PROFILE_SEARCH } from "../../../../api/constants";
import Blink from 'react-blink-text';

//Componets
import CustomToggle from "../../../dropdowns";
import SearchResult from '../../../searchSection/searchResult';
// import DropdownMenu from "react-bootstrap/esm/DropdownMenu";

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
// import state selectors
import { setGeneralSettings, setUserData, setEventPaymentSettings } from '../../../../store/setting/actions'
// Import selectors & action from setting store
import * as SettingSelector from '../../../../store/setting/selectors'


//image
import logo from '../../../../assets/images/logo.png'
import ThemeScheme from '../../../setting/sections/theme-scheme';
import SidebarWithoutLogin from '../SidebarStyle/sidebarWithoutLogin';
import EventInvitationPopupCard from '../../../event/eventInvitationPopupCard';


const Header = ({ notificationCount }) => {
  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };

  const [showSideBarWithoutLogin, setShowSideBarWithoutLogin] = useState(false);
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  let profileData = (useSelector((state) => state.setting.setting.user_data))
  profileData = profileData ? JSON.parse(profileData) : "";

  let generalSettingsData = (useSelector((state) => state.setting.setting.general_settings))
  generalSettingsData = generalSettingsData ? JSON.parse(generalSettingsData) : "";

  let upcomingEventList = (useSelector((state) => state.setting.setting.event_payment_settings))
  upcomingEventList = upcomingEventList ? JSON.parse(upcomingEventList) : "";

  const themeScheme = useSelector(SettingSelector.theme_scheme)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    //console.log("token", localStorage.getItem("uzoneAuthToken"))
    getGeneralSettings()

    if (localStorage.getItem("uzoneAuthToken")) {
      getProfileDetails()
      if (profileData?.user_type != 4 && profileData?.user_type != 2) {
        getUpcomingEventList()
      }
    } else {
      localStorage.setItem('uzoneAuthToken', "")
      dispatch(setUserData(JSON.stringify("")));
      navigate('/')
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);

  }, []);

  async function getProfileDetails() {
    try {
      const res = await apiHandler(PROFILE_DETAILS, "POST")
      if (res.data?.response?.status === 200) {
        //setProfileData(res?.data?.data?.user)
        dispatch(setUserData(JSON.stringify(res?.data?.data?.user)));
      } else {
        // navigate('/auth/sign-in')
        localStorage.setItem('uzoneAuthToken', "")
        dispatch(setUserData(JSON.stringify("")));
        navigate('/')
        //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  async function getUpcomingEventList() {
    try {
      const res = await apiHandler(MY_PARTICIPATED_EVENT, "POST", {
        page: 1,
        limit: 10,
        type: "upcoming",
        unpaid: 1,
      })
      if (res.data?.response?.status === 200) {
        // if (res?.data?.data?.events?.meta?.total > 0) {
        dispatch(setEventPaymentSettings(JSON.stringify(res?.data?.data?.events?.data)));
        // }
      } else {
        //navigate('/auth/sign-in')
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  async function getGeneralSettings() {
    try {
      const res = await apiHandler(GENERAL_SETTINGS, "POST", {
      })
      if (res.data?.response?.status === 200) {
        dispatch(setGeneralSettings(JSON.stringify(res?.data?.data?.generalSettings)));
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div className="iq-top-navbar">
        <Nav
          expand="lg"
          variant="light"
          className={`nav navbar navbar-expand-lg navbar-light iq-navbar ${localStorage.getItem("uzoneAuthToken") ? "p-lg-0" : "p-lg-2"}`}
        >
          <Container fluid className="navbar-inner">
            {
              localStorage.getItem("uzoneAuthToken") ?
                <div className="d-flex align-items-center gap-3  pb-2 pb-lg-0">
                  <Link
                    to={profileData?.user_type === 4 ? "/dashboards/app/events" :
                      profileData?.user_type === 2 ? "/dashboards/app/announcement" : "/dashboard/app/news-feed"}
                    className="d-flex align-items-center gap-2 iq-header-logo"
                  >
                    {/* <svg
                  width="50"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.67733 9.50001L7.88976 20.2602C9.81426 23.5936 14.6255 23.5936 16.55 20.2602L22.7624 9.5C24.6869 6.16666 22.2813 2 18.4323 2H6.00746C2.15845 2 -0.247164 6.16668 1.67733 9.50001ZM14.818 19.2602C13.6633 21.2602 10.7765 21.2602 9.62181 19.2602L9.46165 18.9828L9.46597 18.7275C9.48329 17.7026 9.76288 16.6993 10.2781 15.8131L12.0767 12.7195L14.1092 16.2155C14.4957 16.8803 14.7508 17.6132 14.8607 18.3743L14.9544 19.0239L14.818 19.2602ZM16.4299 16.4683L19.3673 11.3806C18.7773 11.5172 18.172 11.5868 17.5629 11.5868H13.7316L15.8382 15.2102C16.0721 15.6125 16.2699 16.0335 16.4299 16.4683ZM20.9542 8.63193L21.0304 8.5C22.1851 6.5 20.7417 4 18.4323 4H17.8353L17.1846 4.56727C16.6902 4.99824 16.2698 5.50736 15.9402 6.07437L13.8981 9.58676H17.5629C18.4271 9.58676 19.281 9.40011 20.0663 9.03957L20.9542 8.63193ZM14.9554 4C14.6791 4.33499 14.4301 4.69248 14.2111 5.06912L12.0767 8.74038L10.0324 5.22419C9.77912 4.78855 9.48582 4.37881 9.15689 4H14.9554ZM6.15405 4H6.00746C3.69806 4 2.25468 6.50001 3.40938 8.50001L3.4915 8.64223L4.37838 9.04644C5.15962 9.40251 6.00817 9.58676 6.86672 9.58676H10.2553L8.30338 6.22943C7.9234 5.57587 7.42333 5.00001 6.8295 4.53215L6.15405 4ZM5.07407 11.3833L7.88909 16.2591C8.05955 15.7565 8.28025 15.2702 8.54905 14.8079L10.4218 11.5868H6.86672C6.26169 11.5868 5.66037 11.5181 5.07407 11.3833Z"
                    fill="currentColor"
                  />
                </svg> */}
                    <Image src={logo} className="img-fluid" style={{ width: 140 }} />
                    {/* <h3
                      className="logo-title d-none d-sm-block"
                      data-setting="app_name"
                    >
                      Uzone360
                    </h3> */}
                  </Link>
                  {
                    location.pathname === '/' || location.pathname === '/privacy-policy' || location.pathname === '/about-us'
                      || location.pathname === '/contact-us' || location.pathname === '/terms-of-use' ? null :
                      <Link
                        to="#"
                        className="sidebar-toggle"
                        data-toggle="sidebar"
                        data-active="true"
                        onClick={minisidebar}
                      >
                        <div className="icon material-symbols-outlined iq-burger-menu">
                          menu
                        </div>
                      </Link>
                  }

                </div>
                :
                isMobile ?
                  <div className="d-flex align-items-center gap-3  pb-2 pb-lg-0">
                    <Link
                      to="#"
                      className="sidebar-toggle"
                      data-toggle="sidebar"
                      data-active="true"
                      onClick={() => setShowSideBarWithoutLogin(!showSideBarWithoutLogin)}
                    >
                      <div className="icon material-symbols-outlined iq-burger-menu">
                        menu
                      </div>
                    </Link>
                    <Link
                      to={"/"}
                      className="d-flex align-items-center gap-2 iq-header-logo"
                    >
                      <Image src={logo} className="img-fluid" style={{ width: 60 }} />
                      {/* <h3
                        className="logo-title"
                        data-setting="app_name"
                      >
                        Uzone360
                      </h3> */}
                    </Link>
                  </div>
                  :
                  <div className="d-flex align-items-center gap-3 pb-2 pb-lg-0">
                    <Link
                      to={"/"}
                      className="d-flex align-items-center gap-2 iq-header-logo"
                    >
                      <Image src={logo} className="img-fluid" style={{ width: 160 }} />
                      {/* <h3
                        className="logo-title d-none d-sm-block"
                        data-setting="app_name"
                      >
                        Uzone360
                      </h3> */}
                    </Link>
                  </div>
            }

            {
              localStorage.getItem("uzoneAuthToken") ?
                profileData?.user_type === 4 || profileData?.user_type === 2 ? null :
                  <SearchResult /> : null
            }

            {/* <div className="iq-search-bar device-search  position-relative">
              <form
                action="#"
                className="searchbox"
                onClick={handleShow}
                data-bs-toggle="modal"
                data-bs-target="#exampleModalFullscreenSm"
              >
                <Link className="search-link d-none d-lg-block" to="/">
                  <span className="material-symbols-outlined">search</span>
                </Link>
                <Form.Control
                  type="text"
                  className="text search-input form-control bg-soft-primary  d-none d-lg-block"
                  placeholder="Search here test..."
                  value={searchContent} onChange={(e) => {
                    setSearchContent(e.target.value)
                    getSearchProfile(e.target.value)
                  }}
                />
                <Link
                  className="d-lg-none d-flex d-none d-lg-block"
                  to="/"
                  onClick={handleShow}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalFullscreenSm"
                >
                  <span className="material-symbols-outlined">search</span>
                </Link>
              </form>
              <Modal
                show={show}
                onHide={handleClose}
                className="search-modal"
                id="post-modal"
              >
                <div className="modal-dialog modal-fullscreen-lg-down m-0">
                  <Modal.Header className="py-2">
                    <div className="d-flex align-items-center justify-content-between d-lg-none w-100">
                      <form
                        action="#"
                        className="searchbox w-50"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalFullscreenSm"
                        onClick={handleShow}
                      >
                        <Link className="search-link" to="/">
                          <span className="material-symbols-outlined">
                            search
                          </span>
                        </Link>

                        <Form.Control
                          type="text"
                          className="text search-input bg-soft-primary"
                          placeholder="Search here..."
                          value={searchContent} 
                          onChange={(e) => {
                            setSearchContent(e.target.value)
                            getSearchProfile(e.target.value)
                          }}
                        />

                      </form>

                      <Link
                        to="/"
                        className="material-symbols-outlined text-dark"
                        onClick={handleClose}
                      >
                        close
                      </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-between ms-auto w-100">
                      <h5 className=" h4" id="exampleModalFullscreenLabel">
                        Recent
                      </h5>

                      <Link to="/" className="text-dark">
                        Clear All
                      </Link>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="p-0">
                    <div className="d-flex d-lg-none align-items-center justify-content-between w-100 p-3 pb-0">
                      <h5 className=" h4" id="exampleModalFullscreenLabel">
                        Recent
                      </h5>

                      <Link to="/" className="text-dark">
                        Clear All
                      </Link>
                    </div>
                    <div className="d-flex align-items-center border-bottom search-hover py-2 px-3">
                      <div className="flex-shrink-0">
                        <Image
                          className="align-self-center img-fluid avatar-50 rounded-pill"
                          src={user6}
                          alt=""
                          loading="lazy"
                        />
                      </div>

                      <div className="d-flex flex-column ms-3">
                        <Link to="/" className="h5">
                          Paige Turner
                        </Link>

                        <span>Paige001</span>
                      </div>

                      <div className="d-flex align-items-center ms-auto">
                        <Link to="/" className="me-3 d-flex align-items-center">
                          <small>Follow</small>{" "}
                        </Link>

                        <Link
                          to="/"
                          className="material-symbols-outlined text-dark"
                        >
                          close
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex align-items-center border-bottom search-hover py-2 px-3">
                      <div className="flex-shrink-0">
                        <Image
                          className="align-self-center img-fluid avatar-50 rounded-pill"
                          src={user7}
                          alt=""
                          loading="lazy"
                        />
                      </div>

                      <div className="d-flex flex-column ms-3">
                        <Link to="/" className="h5">
                          Monty Carlo
                        </Link>

                        <span>Carlo.m</span>
                      </div>

                      <div className="d-flex align-items-center ms-auto">
                        <Link to="/" className="me-3 d-flex align-items-center">
                          <small>Unfollow</small>{" "}
                        </Link>

                        <Link
                          to="/"
                          className="material-symbols-outlined text-dark"
                        >
                          close
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex align-items-center search-hover py-2 px-3 border-bottom">
                      <div className="flex-shrink-0">
                        <Image
                          className="align-self-center img-fluid avatar-50 rounded-pill"
                          src={user8}
                          alt=""
                          loading="lazy"
                        />
                      </div>

                      <div className="d-flex flex-column ms-3">
                        <Link to="/" className="h5">
                          Paul Molive
                        </Link>

                        <span>Paul.45</span>
                      </div>

                      <div className="d-flex align-items-center ms-auto">
                        <Link to="/" className="me-3 d-flex align-items-center">
                          <small>Request</small>{" "}
                        </Link>

                        <Link
                          to="/"
                          className="material-symbols-outlined text-dark"
                        >
                          close
                        </Link>
                      </div>
                    </div>
                    <div className="">
                      <h4 className="px-3 py-2">Suggestions</h4>

                      <div className="suggestion-card px-3 d-flex">
                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user8}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Ammy Paul
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user9}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Roger Carlo
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Unfollow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story ">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user10}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Justin Molive
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile ">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user11}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Roy Fisher
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Request</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user12}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Johan Carlo
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user13}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              MedrLink Miles
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Unfollow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user14}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Aohan Paul
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Request</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user15}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Rokni Joy
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user16}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Sepid Ryan
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Unfollow</small>{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </div>
              </Modal>
            </div> */}

            {
              localStorage.getItem("uzoneAuthToken") ?
                <ul className="navbar-nav navbar-list">
                  {
                    upcomingEventList?.length > 0 ?
                      // <Nav.Item as="li">
                      <Dropdown as="li" className="nav-item user-dropdown">
                        <Dropdown.Toggle
                          href="#"
                          as={CustomToggle}
                          variant="d-flex align-items-center"
                        >
                          <div className="text-success d-flex align-items-center">
                            <i className="icon material-symbols-outlined" style={{ color: "red" }}>celebration</i>
                            <Blink text="New Invitation" fontSize="14px" color="red"></Blink>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="sub-drop caption-menu" style={{ marginTop: "68px", padding: "2px", boxShadow: "0px 4px 10px #00000055" }}>
                          <div style={{ background: "white", padding: "10px" }}>
                            {
                              upcomingEventList.map((elm, i) => (
                                <EventInvitationPopupCard elm={elm} getEventList={getUpcomingEventList} key={elm?.id} isPast={false} />
                              ))
                            }
                          </div>
                        </Dropdown.Menu>
                      </Dropdown> : null
                  }
                  {/* <Link to="/dashboards/app/my-participaion-events" className="d-flex align-items-center">
                          <div className="text-success d-flex align-items-center">
                            <i className="icon material-symbols-outlined" style={{ color: "red" }}>celebration</i>
                            <Blink text="New Invitation" fontSize="14px" color="red"></Blink>
                          </div>
                        </Link> 
                       </Nav.Item> : null
                       */}
                  <Nav.Item as="li">
                    <Link to={profileData?.user_type === 4 ? "/dashboards/app/events" :
                      profileData?.user_type === 2 ? "/dashboards/app/announcement" : "/"}
                      className="d-flex align-items-center">
                      <i className="material-symbols-outlined">home</i>
                      <span className="mobile-text d-none ms-3">Home</span>
                    </Link>
                  </Nav.Item>

                  {
                    profileData?.user_type === 2 || profileData?.user_type === 4 ? null :
                      <Nav.Item as="li">
                        <Link to={"/dashboard/app/news-feed"}
                          className="d-flex align-items-center">
                          <i className="material-symbols-outlined">newspaper</i>
                          <span className="mobile-text d-none ms-3">Newsfeed</span>
                        </Link>
                      </Nav.Item>
                  }

                  {/* <Nav.Item as="li" className="d-lg-none">
                <div className="iq-search-bar device-search  position-relative">
                  <form
                    action="#"
                    className="searchbox"
                    onClick={handleShow}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalFullscreenSm"
                  >
                    <Link className="search-link d-none d-lg-block" to="/">
                      <span className="material-symbols-outlined">search</span>
                    </Link>
                    <Form.Control
                      type="text"
                      className="text search-input form-control bg-soft-primary  d-none d-lg-block"
                      placeholder="Search here..."
                    />
                    <Link
                      className="d-lg-none d-flex"
                      to="/"
                      onClick={handleShow}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalFullscreenSm"
                    >
                      <span className="material-symbols-outlined">search</span>
                    </Link>
                  </form>

                  <Modal
                    show={show}
                    onHide={handleClose}
                    className="search-modal"
                    id="post-modal"
                  >
                    <div className="modal-dialog modal-fullscreen-lg-down m-0">

                      <Modal.Header className="py-2">
                        <div className="d-flex align-items-center justify-content-between d-lg-none w-100">
                          <form
                            action="#"
                            className="searchbox w-50"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModalFullscreenSm"
                            onClick={handleShow}
                          >
                            <Link className="search-link" to="/">
                              <span className="material-symbols-outlined">
                                search
                              </span>
                            </Link>

                            <Form.Control
                              type="text"
                              className="text search-input bg-soft-primary"
                              placeholder="Search here..."
                            />
                          </form>

                          <Link
                            to="/"
                            className="material-symbols-outlined text-dark"
                            onClick={handleClose}
                          >
                            close
                          </Link>
                        </div>
                        <div className="d-flex align-items-center justify-content-between ms-auto w-100">
                          <h5 className=" h4" id="exampleModalFullscreenLabel">
                            Recent
                          </h5>

                          <Link to="/" className="text-dark">
                            Clear All
                          </Link>
                        </div>
                      </Modal.Header>
                      <Modal.Body className="p-0">
                        <div className="d-flex d-lg-none align-items-center justify-content-between w-100 p-3 pb-0">
                          <h5 className=" h4" id="exampleModalFullscreenLabel">
                            Recent
                          </h5>

                          <Link to="/" className="text-dark">
                            Clear All
                          </Link>
                        </div>
                        <div className="d-flex align-items-center border-bottom search-hover py-2 px-3">
                          <div className="flex-shrink-0">
                            <Image
                              className="align-self-center img-fluid avatar-50 rounded-pill"
                              src={user6}
                              alt=""
                              loading="lazy"
                            />
                          </div>

                          <div className="d-flex flex-column ms-3">
                            <Link to="/" className="h5">
                              Paige Turner
                            </Link>

                            <span>Paige001</span>
                          </div>

                          <div className="d-flex align-items-center ms-auto">
                            <Link to="/" className="me-3 d-flex align-items-center">
                              <small>Follow</small>{" "}
                            </Link>

                            <Link
                              to="/"
                              className="material-symbols-outlined text-dark"
                            >
                              close
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex align-items-center border-bottom search-hover py-2 px-3">
                          <div className="flex-shrink-0">
                            <Image
                              className="align-self-center img-fluid avatar-50 rounded-pill"
                              src={user7}
                              alt=""
                              loading="lazy"
                            />
                          </div>

                          <div className="d-flex flex-column ms-3">
                            <Link to="/" className="h5">
                              Monty Carlo
                            </Link>

                            <span>Carlo.m</span>
                          </div>

                          <div className="d-flex align-items-center ms-auto">
                            <Link to="/" className="me-3 d-flex align-items-center">
                              <small>Unfollow</small>{" "}
                            </Link>

                            <Link
                              to="/"
                              className="material-symbols-outlined text-dark"
                            >
                              close
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex align-items-center search-hover py-2 px-3 border-bottom">
                          <div className="flex-shrink-0">
                            <Image
                              className="align-self-center img-fluid avatar-50 rounded-pill"
                              src={user8}
                              alt=""
                              loading="lazy"
                            />
                          </div>

                          <div className="d-flex flex-column ms-3">
                            <Link to="/" className="h5">
                              Paul Molive
                            </Link>

                            <span>Paul.45</span>
                          </div>

                          <div className="d-flex align-items-center ms-auto">
                            <Link to="/" className="me-3 d-flex align-items-center">
                              <small>Request</small>{" "}
                            </Link>

                            <Link
                              to="/"
                              className="material-symbols-outlined text-dark"
                            >
                              close
                            </Link>
                          </div>
                        </div>
                        <div className="">
                          <h4 className="px-3 py-2">Suggestions</h4>

                          <div className="suggestion-card px-3 d-flex">
                            <div className="text-center story">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user8}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Ammy Paul
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Follow</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user9}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Roger Carlo
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Unfollow</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story ">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user10}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Justin Molive
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Follow</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story">
                              <div className="story-profile ">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user11}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Roy Fisher
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Request</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user12}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Johan Carlo
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Follow</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user13}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  MedrLink Miles
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Unfollow</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user14}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Aohan Paul
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Request</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user15}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Rokni Joy
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Follow</small>{" "}
                              </Link>
                            </div>

                            <div className="text-center story">
                              <div className="story-profile">
                                <Image
                                  className="avatar-50 rounded-pill"
                                  src={user16}
                                  alt=""
                                  loading="lazy"
                                />

                                <Link
                                  to="/"
                                  className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                                >
                                  Sepid Ryan
                                </Link>
                              </div>

                              <Link
                                to="/"
                                className="d-lg-none align-items-center d-flex"
                              >
                                <small>Unfollow</small>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </div>
                  </Modal>
                </div>
              </Nav.Item> */}
                  {profileData?.user_type === 4 || profileData?.user_type === 2 ? null :
                    <Nav.Item as="li">
                      <Link to="/dashboard/app/people-you-may-know" className="d-flex align-items-center">
                        <i className="material-symbols-outlined">group</i>
                        <span className="mobile-text d-none ms-3">People You May Know</span>
                      </Link>
                    </Nav.Item>
                  }

                  {profileData?.user_type === 4 ? null :
                    <Nav.Item as="li">
                      <Link to="/chat/index" className="d-flex align-items-center">
                        <i className="material-symbols-outlined">message</i>
                        <span className="mobile-text d-none ms-3">Chat</span>
                      </Link>
                    </Nav.Item>
                  }

                  {/* <Dropdown as="li" className="nav-item">
                <Dropdown.Toggle
                  href="/"
                  as={CustomToggle}
                  variant="d-flex align-items-center"
                >
                  <span className="material-symbols-outlined">group</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="sub-drop sub-drop-large">
                  <Card className="shadow-none m-0">
                    <Card.Header className="d-flex justify-content-between bg-primary">
                      <div className="header-title">
                        <h5 className="mb-0 text-white">Friend Request</h5>
                      </div>
                      <small className="badge  bg-light text-dark ">4</small>
                    </Card.Header>
                    <Card.Body className="p-0">
                      <div className="iq-friend-request">
                        <div className="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <Image
                              className="avatar-40 rounded"
                              src={user1}
                              alt=""
                              loading="lazy"
                            />
                            <div className="ms-3">
                              <h6 className="mb-0 ">Jaques Amole</h6>
                              <p className="mb-0">40 friends</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <Link
                              to="#"
                              className="me-3 btn btn-primary rounded"
                            >
                              Confirm
                            </Link>
                            <Link
                              to="#"
                              className="me-3 btn btn-secondary rounded"
                            >
                              Delete Request
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="iq-friend-request">
                        <div className="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <Image
                              className="avatar-40 rounded"
                              src={user2}
                              alt=""
                              loading="lazy"
                            />
                            <div className="ms-3">
                              <h6 className="mb-0 ">Lucy Tania</h6>
                              <p className="mb-0">12 friends</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <Link
                              to="#"
                              className="me-3 btn btn-primary rounded"
                            >
                              Confirm
                            </Link>
                            <Link
                              to="#"
                              className="me-3 btn btn-secondary rounded"
                            >
                              Delete Request
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="iq-friend-request">
                        <div className="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <Image
                              className="avatar-40 rounded"
                              src={user3}
                              alt=""
                              loading="lazy"
                            />
                            <div className=" ms-3">
                              <h6 className="mb-0 ">Manny Petty</h6>
                              <p className="mb-0">3 friends</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <Link
                              to="#"
                              className="me-3 btn btn-primary rounded"
                            >
                              Confirm
                            </Link>
                            <Link
                              to="#"
                              className="me-3 btn btn-secondary rounded"
                            >
                              Delete Request
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="iq-friend-request">
                        <div className="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <Image
                              className="avatar-40 rounded"
                              src={user4}
                              alt=""
                              loading="lazy"
                            />
                            <div className="ms-3">
                              <h6 className="mb-0 ">Marsha Mello</h6>
                              <p className="mb-0">15 friends</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <Link
                              to="#"
                              className="me-3 btn btn-primary rounded"
                            >
                              Confirm
                            </Link>
                            <Link
                              to="#"
                              className="me-3 btn btn-secondary rounded"
                            >
                              Delete Request
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <Link to="#" className=" btn text-primary">
                          View More Request
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown> */}

                  <Nav.Item as="li">
                    <Link to="/dashboard/app/notification" className="d-flex align-items-center">
                      <i className="material-symbols-outlined">notifications</i>
                      <span className="mobile-text d-none ms-3">All Notifications</span>
                      {
                        notificationCount > 0 ?
                          isMobile ?
                            <span class="icon-button__badge d-none">{notificationCount}</span> :
                            <span class="icon-button__badge ">{notificationCount}</span> : null
                      }
                    </Link>
                  </Nav.Item>
                  {/* <Dropdown as="li" className="nav-item ">
                <Dropdown.Toggle
                  href="#"
                  as={CustomToggle}
                  variant="search-toggle d-flex align-items-center"
                >
                  <i className="material-symbols-outlined">notifications</i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="sub-drop">
                  <Card className="shadow-none m-0">
                    <Card.Header className="d-flex justify-content-between bg-primary">
                      <div className="header-title bg-primary">
                        <h5 className="mb-0 text-white ">All Notifications</h5>
                      </div>
                      <small className="badge  bg-light text-dark">4</small>
                    </Card.Header>
                    <Card.Body className="p-0">
                      <Link to="#" className="iq-sub-card">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <Image
                              className="avatar-40 rounded"
                              src={user1}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                          <div className="ms-3 w-100">
                            <h6 className="mb-0 ">Emma Watson Bni</h6>
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="mb-0">95 MB</p>
                              <small className="float-right font-size-12">
                                Just Now
                              </small>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <Image
                              className="avatar-40 rounded"
                              src={user2}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                          <div className="ms-3 w-100">
                            <h6 className="mb-0 ">New customer is join</h6>
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="mb-0">Cyst Bni</p>
                              <small className="float-right font-size-12">
                                5 days ago
                              </small>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <Image
                              className="avatar-40 rounded"
                              src={user3}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                          <div className="ms-3 w-100">
                            <h6 className="mb-0 ">Two customer is left</h6>
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="mb-0">Cyst Bni</p>
                              <small className="float-right font-size-12">
                                2 days ago
                              </small>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <Image
                              className="avatar-40 rounded"
                              src={user4}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                          <div className="w-100 ms-3">
                            <h6 className="mb-0 ">New Mail from Fenny</h6>
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="mb-0">Cyst Bni</p>
                              <small className="float-right font-size-12">
                                3 days ago
                              </small>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown> */}

                  {/* <Dropdown as="li" className="nav-item">
                <Dropdown.Toggle
                  href="#"
                  as={CustomToggle}
                  variant="d-flex align-items-center"
                >
                  <i className="material-symbols-outlined">mail</i>
                  <span className="mobile-text d-none ms-3">
                    Message
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="sub-drop">
                  <Card className="shadow-none m-0">
                    <Card.Header className="d-flex justify-content-between bg-primary">
                      <div className="header-title bg-primary">
                        <h5 className="mb-0 text-white">All Message</h5>
                      </div>
                      <small className="badge bg-light text-dark">4</small>
                    </Card.Header>
                    <Card.Body className="p-0 ">
                      <Link to="#" className="iq-sub-card">
                        <div className="d-flex  align-items-center">
                          <div className="">
                            <Image
                              className="avatar-40 rounded"
                              src={user1}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                          <div className=" w-100 ms-3">
                            <h6 className="mb-0 ">Bni Emma Watson</h6>
                            <small className="float-left font-size-12">
                              13 Jun
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <Image
                              className="avatar-40 rounded"
                              src={user2}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                          <div className="ms-3">
                            <h6 className="mb-0 ">Lorem Ipsum Watson</h6>
                            <small className="float-left font-size-12">
                              20 Apr
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <Image
                              className="avatar-40 rounded"
                              src={user3}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                          <div className="ms-3">
                            <h6 className="mb-0 ">Why do we use it?</h6>
                            <small className="float-left font-size-12">
                              30 Jun
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <Image
                              className="avatar-40 rounded"
                              src={user4}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                          <div className="ms-3">
                            <h6 className="mb-0 ">Variations Passages</h6>
                            <small className="float-left font-size-12">
                              12 Sep
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#" className="iq-sub-card">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <Image
                              className="avatar-40 rounded"
                              src={user5}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                          <div className="ms-3">
                            <h6 className="mb-0 ">
                              Lorem Ipsum generators
                            </h6>
                            <small className="float-left font-size-12">
                              5 Dec
                            </small>
                          </div>
                        </div>
                      </Link>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown> */}

                  {/* <Nav.Item as="li" className="d-lg-none">
              <Link
                to="/dashboard/app/notification"
                className="d-flex align-items-center"
              >
                <i className="material-symbols-outlined">notifications</i>
                <span className="mobile-text  ms-3 d-none">Notifications</span>
              </Link>
  </Nav.Item>*/}
                  <Nav.Item className="nav-item d-none d-lg-none">
                    <Link
                      to="#"
                      className="dropdown-toggle d-flex align-items-center"
                      id="mail-drop-1"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="material-symbols-outlined">mail</i>
                      <span className="mobile-text  ms-3">Message</span>
                    </Link>
                  </Nav.Item>
                  <Dropdown as="li" className="nav-item user-dropdown">
                    <Dropdown.Toggle
                      href="#"
                      as={CustomToggle}
                      variant="d-flex align-items-center"
                    >
                      <Image
                        src={profileData?.user_setting?.photo ?
                          profileData?.user_setting?.photo :
                          "https://uzone360live.s3.amazonaws.com/noimage.png"}
                        className="img-fluid rounded-circle me-3"
                        alt="user"
                        loading="lazy"
                      />
                      <div className="caption d-none d-lg-block">
                        <h6 className="mb-0 line-height">{profileData?.first_name && (profileData?.first_name)}</h6>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="sub-drop caption-menu">
                      {
                        profileData?.user_type === 4 ?
                          <Card className="shadow-none m-0">
                            {
                              profileData?.user_type === 4 ? null :
                                <Card.Header>
                                  <div className="header-title">
                                    <h5 className="mb-0 ">Hello {profileData?.first_name && (profileData?.first_name)}</h5>
                                  </div>
                                </Card.Header>
                            }
                            <Card.Body className="p-0 ">
                              <Link to="#" className="mb-0 h6" onClick={() => {
                                localStorage.setItem('uzoneAuthToken', "")
                                dispatch(setUserData(JSON.stringify("")));
                                navigate('/')
                              }}>
                                <div className="d-flex align-items-center iq-sub-card">
                                  <span className="material-symbols-outlined">login</span>
                                  <div className="ms-3">
                                    <h6>Sign out</h6>
                                  </div>
                                </div>
                              </Link>
                              <div style={{ margin: "15px" }}>
                                <ThemeScheme themeScheme={themeScheme}></ThemeScheme>
                              </div>
                            </Card.Body>
                          </Card> :
                          <Card className="shadow-none m-0">
                            {
                              profileData?.user_type === 4 ? null :
                                <Card.Header>
                                  <div className="header-title">
                                    <h5 className="mb-0 ">Hello {profileData?.first_name && (profileData?.first_name)}</h5>
                                  </div>
                                </Card.Header>
                            }
                            <Card.Body className="p-0 ">
                              {
                                profileData?.user_type === 2 ? null :
                                  <>
                                    <Link to="/dashboard/app/news-feed" className="mb-0 h6">
                                      <div className="d-flex align-items-center iq-sub-card border-0">
                                        <span className="material-symbols-outlined">
                                          line_style
                                        </span>
                                        <div className="ms-3">
                                          <h6>Newsfeed</h6>
                                        </div>
                                      </div>
                                    </Link>
                                    <Link to="/dashboard/app/profile" className="mb-0 h6">
                                      <div className="d-flex align-items-center iq-sub-card border-0">
                                        <span className="material-symbols-outlined">
                                          line_style
                                        </span>
                                        <div className="ms-3">
                                          <h6>My Profile</h6>
                                        </div>
                                      </div>
                                    </Link>
                                    <Link to="/dashboard/app/user-profile-edit" className="mb-0 h6">
                                      <div className="d-flex align-items-center iq-sub-card border-0">
                                        <span className="material-symbols-outlined">
                                          edit_note
                                        </span>
                                        <div className="ms-3">
                                          <h6>Edit Profile</h6>
                                        </div>
                                      </div>
                                    </Link>
                                    {/* <Link to="/dashboard/app/user-account-setting" className="mb-0 h6">
                                  <div className="d-flex align-items-center iq-sub-card border-0">
                                    <span className="material-symbols-outlined">
                                      manage_accounts
                                    </span>
                                    <div className="ms-3">
                                      <h6>Account settings</h6>
                                    </div>
                                  </div>
                                </Link> */}
                                  </>
                              }
                              <Link to="#" className="mb-0 h6" onClick={() => {
                                localStorage.setItem('uzoneAuthToken', "")
                                dispatch(setUserData(JSON.stringify("")));
                                navigate('/')
                              }}>
                                <div className="d-flex align-items-center iq-sub-card">
                                  <span className="material-symbols-outlined">login</span>
                                  <div className="ms-3">
                                    <h6>Sign out</h6>
                                  </div>
                                </div>
                              </Link>
                              <div style={{ margin: "15px" }}>
                                <ThemeScheme themeScheme={themeScheme}></ThemeScheme>
                              </div>
                            </Card.Body>
                          </Card>
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </ul> :
                isMobile ? null :
                  <Accordion as="ul" className="navbar-nav iq-main-menu d-flex align-items-center" id="sidebar-menu">
                    <li className={`${location.pathname === '/dashboard/app/news-feed' ? 'active' : ''} nav-item`}>
                      <Link className={`${location.pathname === '/' ? 'active' : ''} nav-link`} to="/"
                        onClick={() => setShowSideBarWithoutLogin(false)}>
                        <span className="item-name fs-6"> Home </span>
                      </Link>
                    </li>
                    <li className={`${location.pathname === '/dashboard/app/news-feed' ? 'active' : ''} nav-item`}>
                      <Link className={`${location.pathname === '/dashboard/app/publicEvents' ? 'active' : ''} nav-link`} to="/dashboard/app/publicEvents"
                        onClick={() => setShowSideBarWithoutLogin(false)}>
                        <span className="item-name fs-6"> {generalSettingsData?.event_heading_text} </span>
                      </Link>
                    </li>
                    <li className={`${location.pathname === '/dashboard/app/news-feed' ? 'active' : ''} nav-item`}>
                      <Link className={`${location.pathname === '/dashboard/app/public-blog' ? 'active' : ''} nav-link`} to="/dashboard/app/public-blog"
                        onClick={() => setShowSideBarWithoutLogin(false)}>
                        <span className="item-name fs-6"> {generalSettingsData?.blog_heading_text} </span>
                      </Link>
                    </li>
                    <li className={`${location.pathname === '/dashboard/app/news-feed' ? 'active' : ''} nav-item`}>
                      <Link className={`${location.pathname === '/auth/login-with-otp' ? 'active' : ''} nav-link`} to="/auth/login-with-otp"
                        onClick={() => {
                          localStorage.setItem('uzoneSelectedTicketId', "")
                          setShowSideBarWithoutLogin(false)
                        }}>
                        <span className="item-name fs-6"> My Tickets </span>
                      </Link>
                    </li>
                    {/* <li className={`${location.pathname === '/' ? 'active' : ''} nav-item`}>
                     <Link className={`${location.pathname === '/dashboard/extrapages/privacy-policy' ? 'active' : ''} nav-link`} to="/dashboard/extrapages/privacy-policy">
                         <span className="item-name fs-6"> Privacy Policy </span>
                     </Link>
                 </li>
                 <li className={`${location.pathname === '/' ? 'active' : ''} nav-item`}>
                     <Link className={`${location.pathname === '/dashboard/extrapages/terms-of-service' ? 'active' : ''} nav-link`} to="/dashboard/extrapages/terms-of-service">
                         <span className="item-name fs-6"> Terms of Use </span>
                     </Link>
                 </li>
                 <li className={`${location.pathname === '/' ? 'active' : ''} nav-item`}>
                     <Link className={`${location.pathname === '/dashboard/extrapages/pages-faq' ? 'active' : ''} nav-link`} to="/dashboard/extrapages/pages-faq">
                         <span className="item-name fs-6"> FAQ </span>
                     </Link>
                 </li> */}
                    <li className="nav-item">
                      <Link className={`${location.pathname === '/auth/sign-in' ? 'active' : ''} nav-link`} to="/auth/sign-in">
                        <Button variant="accent" className="mb-1" disabled style={{ width: "100px" }}>Ligin</Button>{' '}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={`${location.pathname === '/auth/sign-up' ? 'active' : ''} nav-link`} to="/auth/sign-up">
                        <Button variant="primary" className="mb-1" disabled style={{ width: "100px" }}>Sign up</Button>{' '}
                      </Link>
                    </li>
                  </Accordion>
            }

            {
              showSideBarWithoutLogin ?
                <SidebarWithoutLogin
                  setShowSideBarWithoutLogin={setShowSideBarWithoutLogin} />
                : null
            }
          </Container>
        </Nav>
      </div>
    </>
  );
};

export default Header;
