import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Card, Container, Form, Col, Nav, Row, Image } from 'react-bootstrap'
import { apiHandler } from '../../api/apihandler';
import { BLOG_LIST, PUBLIC_EVENT_LIST, FEEDS_LIST } from "../../api/constants";
import { InView } from "react-intersection-observer";
import { useSelector } from 'react-redux';
import Moment from 'moment';
//profile-header
import ProfileHeader from '../../components/profile-header'

// images
import img6 from '../../assets/images/page-img/profile-bg6.jpg'
import loader from '../../assets/images/page-img/page-load-loader.gif'
import PublicEventCard from '../../components/event/publicEventCard';
import HeroHeader from '../../components/event/heroHeader';
import GetApp from '../../components/getApp';
import BecomeInstructor from '../../components/becomeInstructor';
import PublicBlogCard from '../../components/blog/publicBlogCard';
import Announcement from '../../components/announcement';
import FeedPostCard from '../../components/feeds/feedPostCard';
import CreatePostInputBox from '../../components/createPost/createPostInputBox';
import StoriesSection from '../../components/stories/storiesSection';
import PeopleYouMayKnowCard from '../../components/peopleYouMayKnow/peopleYouMayKnowCard';
import RightSideAdsCard from '../../components/ads/rightSideAdsCard';
import BlogCard from '../../components/blog/blogCard';
import MiddleAdCard from '../../components/ads/middleAdCard';
import EventCard from '../../components/event/eventCard';

const Index = () => {
   const navigate = useNavigate();
   const [upcomingEventList, setUpcomingEventList] = useState([]);
   const [pastEventList, setPastEventList] = useState([]);
   const [blogList, setBlogList] = useState([]);
   const [page, setPage] = useState(1);
   const [maxPage, setMaxPage] = useState(1);
   const [isPast, setIsPast] = useState(false);
   const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);
   let generalSettingsData = (useSelector((state) => state.setting.setting.general_settings))
   generalSettingsData = generalSettingsData ? JSON.parse(generalSettingsData) : "";
   const [feedsList, setFeedsList] = useState([]);
   const [adsPageVal, setAdsPageVal] = useState(1);

   const [isFirst, setIsFirst] = useState(true);
   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      setIsFirst(false)
   }, [isFirst]);

   useEffect(() => {
      getUpcomingEventList(1)
      getBlogList()

      if (localStorage.getItem("uzoneAuthToken")) {
         getFeedsList(1)
      }
   }, []);

   useEffect(() => {
      if (isPast)
         getPastEventList(1)
      else
         getUpcomingEventList(1)
   }, [isPast]);

   function upcomingRefreshApi() {
      setPage(1)
      getUpcomingEventList(1)
   }

   function pastRefreshApi() {
      setPage(1)
      getPastEventList(1)
   }

   const loadMore = (inView) => {
      if (inView) {
         if (page < maxPage) {
            getFeedsList(page + 1)
            //console.log("scroll working...", page + 1)
            setPage(page + 1)
         }
      }
   };

   function refreshApi() {
      setPage(1)
      getFeedsList(1)
   }

   async function updateAdsPageVal(pageVal) {
      setAdsPageVal(pageVal)
   }
   // const loadMore = (inView) => {
   //    if (inView) {
   //       if (page < maxPage) {
   //          getEventList(page + 1)
   //          setPage(page + 1)
   //       }
   //    }
   // };

   async function getUpcomingEventList(pageVal) {
      setShowMoreDataLoader(true)
      try {
         const res = await apiHandler(PUBLIC_EVENT_LIST, "POST", {
            type: "upcoming",
            page: pageVal,
            limit: 8
         })
         if (res.data?.response?.status === 200) {
            setMaxPage(res?.data?.data?.events?.meta?.last_page)
            if (res?.data?.data?.events?.meta?.total > 0) {
               if (pageVal === 1) {
                  setUpcomingEventList(res?.data?.data?.events?.data)
               } else {
                  setUpcomingEventList(upcomingEventList.concat(res?.data?.data?.events?.data))
               }
            }
         }
      } catch (error) {
         console.error(error);
      }
      finally {
         setShowMoreDataLoader(false)
      }
   }

   async function getPastEventList(pageVal) {
      setShowMoreDataLoader(true)
      try {
         const res = await apiHandler(PUBLIC_EVENT_LIST, "POST", {
            type: "past",
            page: pageVal,
            limit: 8
         })
         if (res.data?.response?.status === 200) {
            setMaxPage(res?.data?.data?.events?.meta?.last_page)
            if (res?.data?.data?.events?.meta?.total > 0) {
               if (pageVal === 1) {
                  setPastEventList(res?.data?.data?.events?.data)
               } else {
                  setPastEventList(pastEventList.concat(res?.data?.data?.events?.data))
               }
            }
         }
      } catch (error) {
         console.error(error);
      }
      finally {
         setShowMoreDataLoader(false)
      }
   }

   async function getBlogList() {
      setShowMoreDataLoader(true)
      try {
         const res = await apiHandler(BLOG_LIST, "POST", {
            page: 1,
            limit: 8
         })
         if (res.data?.response?.status === 200) {
            if (res?.data?.data?.blogs?.meta?.total > 0) {
               setBlogList(res?.data?.data?.blogs?.data)
            }
         }
      } catch (error) {
         console.error(error);
      }
      finally {
         setShowMoreDataLoader(false)
      }
   }

   async function getFeedsList(pageVal) {
      // getMiddleAdsList(adsPageVal + 1)
      setShowMoreDataLoader(true)
      //setShowLoader(true)
      try {
         const res = await apiHandler(FEEDS_LIST, "POST", {
            page: pageVal,
            limit: 3,
         })
         if (res.data?.response?.status === 200) {
            setMaxPage(res?.data?.data?.feeds?.meta?.last_page)
            if (pageVal === 1) {
               setFeedsList(res?.data?.data?.feeds?.data)
            } else {
               setFeedsList(feedsList.concat(res?.data?.data?.feeds?.data))
            }
         } else {
            //navigate('/auth/sign-in')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      } finally {
         setShowMoreDataLoader(false)
         //setShowLoader(false)
      }
   }

   return (
      <>
         {/* <ProfileHeader title="Events List" img={img6} /> */}
         <HeroHeader />
         <Announcement />

         <div style={{ paddingTop: "10px", paddingBottom: "10px", backgroundColor: "#FFF", }}>
            <div className="title-on-header text-center mt-5 mb-4">
               <div className="data-block">
                  <h1>{generalSettingsData?.event_heading_text}</h1>
                  {
                     localStorage.getItem("uzoneAuthToken") ? null :
                        <h5 style={{ cursor: "pointer" }} onClick={() => { navigate('/auth/sign-in') }}>Login to participate in the {generalSettingsData?.event_heading_text}</h5>
                  }
                  <div className="d-flex justify-content-center align-items-center mt-3">
                     <h5 className="me-2">Upcoming</h5>
                     <Form.Check className="form-switch ">
                        <Form.Check.Input type="checkbox" className={isPast ? "bg-primary" : "bg-white"} id="customSwitch01" //defaultChecked
                           style={{ height: "24px", width: "60px" }} onChange={() => setIsPast(prev => !prev)} />
                     </Form.Check>
                     <h5 className="ms-2">Past</h5>
                  </div>
               </div>
            </div>
            <div id="content-page" >
               <Container style={{ maxWidth: "98.5rem" }}>
                  <div className="d-grid gap-3 d-grid-template-1fr-19 mb-0">
                     {
                        isPast ?
                           pastEventList?.length > 0 ? pastEventList.map((elm, i) => (
                              <>
                                 {
                                    localStorage.getItem("uzoneAuthToken") ?
                                       <EventCard elm={elm} getEventList={pastRefreshApi} key={elm?.id} isPast={true} /> :
                                       <PublicEventCard elm={elm} getEventList={pastRefreshApi} key={elm?.id} isPast={true} />
                                 }
                              </>
                           )) : null :

                           upcomingEventList?.length > 0 ? upcomingEventList.map((elm, i) => (
                              <>
                                 {
                                    localStorage.getItem("uzoneAuthToken") ?
                                       <EventCard elm={elm} getEventList={upcomingRefreshApi} key={elm?.id} isPast={false} /> :
                                       <PublicEventCard elm={elm} getEventList={upcomingRefreshApi} key={elm?.id} isPast={false} />
                                 }
                              </>
                           )) : null
                     }
                  </div>
                  {
                     showMoreDataLoader ?
                        <div className="col-sm-12 text-center">
                           <img src={loader} alt="loader" style={{ height: "100px" }} />
                        </div> : null
                  }
                  {/* <InView as="div" onChange={(inView, entry) => loadMore(inView)} /> */}
               </Container>
            </div>
         </div>

         {
            localStorage.getItem("uzoneAuthToken") ?
               <Container>
                  <div className="title-on-header text-center mt-5 mb-5">
                     <div className="data-block">
                        <h1>News Feed</h1>
                     </div>
                  </div>
                  <Row>
                     <Col lg={8} className="row m-0 p-0">
                        <Col sm={12}>
                           {
                              feedsList?.length > 0 ? feedsList?.map((elm, i) => (
                                 <>
                                    <FeedPostCard elm={elm} getFeedsList={refreshApi} key={elm?.id} feedDetails={false} />
                                    {
                                       (i + 1) % 3 === 0 ?
                                          <MiddleAdCard index={i} adsPageVal={adsPageVal} updateAdsPageVal={updateAdsPageVal} /> : null
                                    }
                                 </>
                              )) : null
                           }
                           {
                              showMoreDataLoader ?
                                 <div className="col-sm-12 text-center">
                                    <img src={loader} alt="loader" style={{ height: "100px" }} />
                                 </div> : null
                           }
                           <InView as="div" onChange={(inView, entry) => loadMore(inView)} />
                        </Col>
                     </Col>
                     <Col lg={4}>
                        <div>
                           <Card className="card-block card-stretch card-height blog-post">
                              <Card.Header className="d-flex justify-content-between">
                                 <div className="header-title">
                                    <h4 className="card-title">{generalSettingsData?.blog_heading_text}</h4>
                                 </div>
                              </Card.Header>
                              <Card.Body>
                                 <ul className="list-inline p-0 mb-0 mt-1">
                                    {
                                       blogList.map((elm, i) => (
                                          <>
                                             {
                                                blogList?.length - 1 === i ?
                                                   <li >
                                                      <Link to={`/dashboard/blog/blog-detail/${elm?.slug}`} className="mb-2">
                                                         <Row className="align-items-top">
                                                            <Col md="4">
                                                               <div className="image-block" >
                                                                  <Image style={{ height: 70 }}
                                                                     src={elm?.default_image?.file_name ? elm?.default_image?.file_name : "https://uzone360live.s3.amazonaws.com/noimage.png"} className="img-fluid rounded w-100" alt="blog-img" />
                                                               </div>
                                                            </Col>
                                                            <Col md="8">
                                                               <div className="blog-description mt-1 mt-md-0">
                                                                  <div className="date mb-1">{Moment(elm?.updated_at).format('DD MMMM YYYY')}</div>
                                                                  <h6>
                                                                     {elm?.title}
                                                                     {/* <Link to={`/dashboard/blog/blog-detail/${elm?.slug}`} className="mb-2">{elm?.title}</Link> */}
                                                                  </h6>
                                                               </div>
                                                            </Col>
                                                         </Row>
                                                      </Link>
                                                   </li> :
                                                   <li className="mb-3">
                                                      <Link to={`/dashboard/blog/blog-detail/${elm?.slug}`} className="mb-2">
                                                         <Row className="align-items-top pb-3 border-bottom">
                                                            <Col md="4">
                                                               <div className="image-block" >
                                                                  <Image style={{ height: 70 }}
                                                                     src={elm?.default_image?.file_name ? elm?.default_image?.file_name : "https://uzone360live.s3.amazonaws.com/noimage.png"} className="img-fluid rounded w-100" alt="blog-img" />
                                                               </div>
                                                            </Col>
                                                            <Col md="8">
                                                               <div className="blog-description mt-1 mt-md-0">
                                                                  <div className="date mb-1">{Moment(elm?.updated_at).format('DD MMMM YYYY')}</div>
                                                                  <h6>
                                                                     <Link to={`/dashboard/blog/blog-detail/${elm?.slug}`} className="mb-2">{elm?.title}</Link>
                                                                  </h6>
                                                               </div>
                                                            </Col>
                                                         </Row>
                                                      </Link>
                                                   </li>
                                             }
                                          </>
                                       ))
                                    }
                                 </ul>
                              </Card.Body>
                           </Card>
                        </div>
                        <RightSideAdsCard />
                     </Col>
                  </Row>
               </Container> : null
         }

         {
            localStorage.getItem("uzoneAuthToken") ? null :
               <BecomeInstructor />
         }

         {
            localStorage.getItem("uzoneAuthToken") ? null :
               <GetApp />
         }

         {
            localStorage.getItem("uzoneAuthToken") ? null :
               <div style={{ paddingTop: "10px", paddingBottom: "70px", backgroundColor: "#FFF" }}>
                  <div className="title-on-header text-center mt-5 mb-5">
                     <div className="data-block">
                        <h1>{generalSettingsData?.blog_heading_text}</h1>
                        <h5 style={{ cursor: "pointer" }} onClick={() => { navigate('/auth/sign-in') }}>Login to like and comment on our {generalSettingsData?.blog_heading_text}</h5>
                     </div>
                  </div>
                  <div id="content-page" >
                     <Container style={{ maxWidth: "98.5rem" }}>
                        <div className="d-grid gap-3 d-grid-template-1fr-19 mb-0">
                           {
                              blogList?.length > 0 ? blogList.map((elm, i) => (
                                 <PublicBlogCard elm={elm} key={elm?.id} />
                              )) : null
                           }
                        </div>
                        {
                           showMoreDataLoader ?
                              <div className="col-sm-12 text-center">
                                 <img src={loader} alt="loader" style={{ height: "100px" }} />
                              </div> : null
                        }
                     </Container>
                  </div>
               </div>
         }
      </>
   )

}

export default Index;