import React, { useState, useEffect, useCallback } from 'react'
import { Container, Row, Col, Card, Tab, Form, Button, Nav, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import Moment from 'moment';
import { Link, useNavigate, Route } from 'react-router-dom'
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { apiHandler } from '../../../api/apihandler';
import { CHANGE_PASSWORD, PROFILE_DETAILS, PROFILE_PIC_UPDATE, PROFILE_UPDATE } from "../../../api/constants";
import { handleImageUpload } from "../../../api/uploads";
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import Swal from 'sweetalert2'
import Cropper from 'react-easy-crop'

import getCroppedImg from '../../../utilities/crop-image'

// Redux Selector / Action
import { useDispatch } from 'react-redux';

// import state selectors
import { setUserData } from '../../../store/setting/actions'
import { useSelector } from 'react-redux';
import LoaderModal from '../../../components/loaderModal';

const UserProfileEdit = () => {

    const navigate = useNavigate();
    const { addToast } = useToasts();
    const dispatch = useDispatch()
    const user_data = JSON.parse(useSelector((state) => state.setting.setting.user_data));

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [mobile, setMobile] = useState('')
    const [gender, setGender] = useState('1')
    const [email, setEmail] = useState('')
    const [bio, setBio] = useState('')
    const [dob, setDOB] = useState('')
    const [website, setWebSite] = useState('')
    const [profileImage, setProfileImage] = useState('')

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [opType, setOPType] = useState('password');
    const [opIcon, setOPIcon] = useState(eyeOff);
    const [npType, setNPType] = useState('password');
    const [npIcon, setNPIcon] = useState(eyeOff);
    const [cpType, setCPType] = useState('password');
    const [cpIcon, setCPIcon] = useState(eyeOff);

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [showCroper, setShowCroper] = useState(false)
    const [imageForCrop, setImageForCrop] = useState("")
    const [rotation, setRotation] = useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const [showLoader, setShowLoader] = useState(false);

    const handleShowCroper = () => {
        setShowCroper(true)
    }
    const handleCloseCroper = () => {
        setShowCroper(false)
    }

    const resizeComplete = async () => {
        await onCropComplete()
        await showCroppedImage()
    }
    const onCropComplete = async (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const showCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageForCrop,
                croppedAreaPixels,
                rotation
            )
            //console.log(croppedImage)
            handleUpload(croppedImage)
            setProfileImage(URL.createObjectURL(croppedImage))
            handleCloseCroper()
        } catch (e) {
            console.error(e)
        }
    }

    const handleUpload = async (file) => {
        setShowLoader(true)
        try {
            const fileCon = new File([file], `${user_data?.slug}${Date.now()}.jpg`, { type: 'image/jpeg' });
            const url = await handleImageUpload(fileCon, "profilePhoto/", "image/jpeg")
            updateProfilePicApi(url)
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const handleOPToggle = () => {
        if (opType === 'password') {
            setOPIcon(eye);
            setOPType('text')
        } else {
            setOPIcon(eyeOff)
            setOPType('password')
        }
    }

    const handleNPToggle = () => {
        if (npType === 'password') {
            setNPIcon(eye);
            setNPType('text')
        } else {
            setNPIcon(eyeOff)
            setNPType('password')
        }
    }

    const handleCPToggle = () => {
        if (cpType === 'password') {
            setCPIcon(eye);
            setCPType('text')
        } else {
            setCPIcon(eyeOff)
            setCPType('password')
        }
    }


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        event.target.value = "";
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                handleShowCroper()
                setImageForCrop(reader.result)
            };
            reader.readAsDataURL(file);
        }
    };

    const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsFirst(false)
    }, [isFirst]);

    useEffect(() => {
        //console.log("token",localStorage.getItem("authToken"))
        if (localStorage.getItem("uzoneAuthToken")) {
            //getProfileDetails()

            setFirstName(user_data?.first_name)
            setLastName(user_data?.last_name)
            setEmail(user_data?.email)
            setMobile(user_data?.user_setting?.mobile_no)
            setWebSite(user_data?.user_setting?.website)
            setDOB(Moment(user_data?.user_setting?.dob).format('YYYY-MM-DD'))
            setGender(user_data?.user_setting?.gender)
            setProfileImage(user_data?.user_setting?.photo)
            setBio(user_data?.user_setting?.bio)
        } else {
            navigate('/')
        }
    }, []);

    function handleOnChange(e) {
        console.log('selected option', e);
        if (e === "option1") {
            setGender(0)
        } else if (e === "option2") {
            setGender(1)
        } else if (e === "option3") {
            setGender(2)
        }
    }

    function handleUpdateSubmit() {
        if (firstName === "") {
            Swal.fire({
                title: 'Error!',
                text: 'Enter first name!',
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (lastName === "") {
            Swal.fire({
                title: 'Error!',
                text: 'Enter last name!',
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (mobile === "") {
            Swal.fire({
                title: 'Error!',
                text: 'Enter mobile number!',
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (dob === "") {
            Swal.fire({
                title: 'Error!',
                text: 'Enter date of birth!',
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (gender === "") {
            Swal.fire({
                title: 'Error!',
                text: 'Please select gender',
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            updateProfile()
        }
    };

    async function getProfileDetails() {
        try {
            const res = await apiHandler(PROFILE_DETAILS, "POST")
            if (res.data?.response?.status === 200) {
                //console.log("profileRes", res)
                //setProfileData(res?.data?.data?.user)
                setFirstName(res?.data?.data?.user?.first_name)
                setLastName(res?.data?.data?.user?.last_name)
                setEmail(res?.data?.data?.user?.email)
                setMobile(res?.data?.data?.user?.user_setting?.mobile_no)
                setWebSite(res?.data?.data?.user?.user_setting?.website)
                setDOB(Moment(res?.data?.data?.user?.user_setting?.dob).format('YYYY-MM-DD'))
                setGender(res?.data?.data?.user?.user_setting?.gender)
                setProfileImage(res?.data?.data?.user?.user_setting?.photo)
                setBio(res?.data?.data?.user?.user_setting?.bio)
            } else {
                navigate('/')
                //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                //console.log("profileRes",res)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    async function updateProfile() {
        try {
            const res = await apiHandler(PROFILE_UPDATE, "POST", {
                first_name: firstName,
                last_name: lastName,
                gender: gender,
                dob: dob,
                mobile_no: mobile,
                interest_id: "1",
                bio: bio,
                website: website
            })
            if (res.data?.response?.status === 200) {
                dispatch(setUserData(JSON.stringify(res?.data?.data?.user)));
                Swal.fire({
                    title: 'Success!',
                    text: 'Your profile updated successfully.',
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
            } else {
                addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                // router.push('/login')
                // console.log("updateProfileRes",res)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    async function updateProfilePicApi(url) {
        try {
            const res = await apiHandler(PROFILE_PIC_UPDATE, "POST", {
                photo: url
            })
            if (res.data?.response?.status == 200) {
                dispatch(setUserData(JSON.stringify(res?.data?.data?.user)));
                Swal.fire({
                    title: 'Success!',
                    text: 'Your profile image uploaded successfully.',
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
            } else if (res.data?.response?.status == 400) {
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                // router.push('/login')
                // console.log("updateProfileRes",res)
                Swal.fire({
                    title: 'Error',
                    text: res?.response?.data?.data?.message,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowLoader(false)
        }
    }

    async function changePassword() {
        const res = await apiHandler(CHANGE_PASSWORD, "POST", {
            new_password: newPassword,
            confirm_password: confirmPassword,
            old_password: oldPassword
        })
        if (res.data?.response?.status === 200) {
            Swal.fire({
                title: 'Success!',
                text: 'Your password changed successfully.',
                icon: 'success',
                confirmButtonText: 'Okay'
            })
        } else {
            addToast(res?.response?.data?.data?.message, { appearance: 'error' });
        }
    }


    return (
        <>
        <LoaderModal showLoader={showLoader}/>
            <Container style={{ marginTop: "10px" }}>
                <Tab.Container defaultActiveKey="first">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body className="p-0">
                                    <div>
                                        <Nav as="ul" variant="pills" className="iq-edit-profile row">
                                            <Nav.Item as="li" className="col-md-6 p-0">
                                                <Nav.Link eventKey="first" role="button">
                                                    Personal Information
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li" className="col-md-6 p-0">
                                                <Nav.Link eventKey="second" role="button">
                                                    Change Password
                                                </Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item as="li" className="col-md-3 p-0">
                                                <Nav.Link eventKey="third" role="button">
                                                    Email and SMS
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li" className="col-md-3 p-0">
                                                <Nav.Link eventKey="fourth" role="button">
                                                    Manage Contact
                                                </Nav.Link>
                                            </Nav.Item> */}
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12}>
                            {/* <div className="iq-edit-list-data"> */}
                            <Tab.Content>
                                <Tab.Pane eventKey="first" className="fade show">
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Personal Information</h4>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            {/* <Form onSubmit={handleSubmit}> */}
                                            <Form.Group className="form-group align-items-center">
                                                <Col md="12">
                                                    <div className="profile-img-edit">
                                                        {/* <img className="profile-pic" src={img1} alt="profile-pic" /> */}
                                                        <img className="profile-pic" style={{ height: 150, width: 150 }}
                                                            src={profileImage ? profileImage : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="profile-pic" />
                                                        <div className="p-image">
                                                            <label htmlFor="imageUpload1">
                                                                <i className="ri-pencil-line upload-button text-white" alt="preview image" />
                                                            </label>
                                                            <input className="file-upload"
                                                                id="imageUpload1" type="file" accept="image/*"
                                                                //multiple="multiple"
                                                                onChange={
                                                                    handleImageChange
                                                                } />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Form.Group>
                                            <Row className="align-items-center">
                                                <Form.Group className="form-group col-sm-6">
                                                    <Form.Label htmlFor="fname" className="form-label">First Name: *</Form.Label>
                                                    <Form.Control type="text" className="form-control" id="fname" placeholder="First Name"
                                                        value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="form-group col-sm-6">
                                                    <Form.Label htmlFor="lname" className="form-label">Last Name: *</Form.Label>
                                                    <Form.Control type="text" className="form-control" id="lname" placeholder="Last Name"
                                                        value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="form-group col-sm-6">
                                                    <Form.Label htmlFor="mobileno" className="form-label">Mobile No: *</Form.Label>
                                                    <Form.Control type="text" className="form-control" id="mobileno" placeholder="Mobile No"
                                                        value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="form-group col-sm-6">
                                                    <Form.Label htmlFor="website" className="form-label">Web site:</Form.Label>
                                                    <Form.Control type="text" className="form-control" id="website" placeholder="Web site"
                                                        value={website} onChange={(e) => setWebSite(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="form-group col-sm-6">
                                                    <Form.Label htmlFor="dob" className="form-label">Date Of Birth: *</Form.Label>
                                                    <Form.Control type="date" className="mb-0" id="dob" placeholder="Date of Birth"
                                                        max={new Date().toISOString().split("T")[0]}
                                                        //defaultValue={setDOB(date_variable)}
                                                        value={dob} onChange={(e) => setDOB(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="form-group col-sm-6">
                                                    <Form.Label className="form-label d-block">Gender: *</Form.Label>
                                                    <Form.Check className="form-check form-check-inline">
                                                        <Form.Check.Input className="form-check-input" checked={gender == 0} onChange={(e) => handleOnChange(e.target.value)} type="radio" name="inlineRadioOptions" id="inlineRadio10" defaultValue="option1" />
                                                        <Form.Check.Label className="form-check-label" htmlFor="inlineRadio10">Male</Form.Check.Label>
                                                    </Form.Check>
                                                    <Form.Check className="form-check form-check-inline">
                                                        <Form.Check.Input className="form-check-input" checked={gender == 1} onChange={(e) => handleOnChange(e.target.value)} type="radio" name="inlineRadioOptions" id="inlineRadio11" defaultValue="option2" />
                                                        <Form.Check.Label className="form-check-label" htmlFor="inlineRadio11">Female</Form.Check.Label>
                                                    </Form.Check>
                                                    <Form.Check className="form-check form-check-inline">
                                                        <Form.Check.Input className="form-check-input" checked={gender == 2} onChange={(e) => handleOnChange(e.target.value)} type="radio" name="inlineRadioOptions" id="inlineRadio12" defaultValue="option3" />
                                                        <Form.Check.Label className="form-check-label" htmlFor="inlineRadio11">Other</Form.Check.Label>
                                                    </Form.Check>
                                                </Form.Group>
                                                {/* <Form.Group className="form-group col-sm-6">
                                                        <Form.Label className="form-label">Marital Status:</Form.Label>
                                                        <Form.Select defaultValue="Single" className="form-select" aria-label="Default select example">
                                                            <option>Single</option>
                                                            <option>Married</option>
                                                            <option>Widowed</option>
                                                            <option>Divorced</option>
                                                            <option>Separated </option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <Form.Group className="form-group col-sm-6">
                                                        <Form.Label className="form-label">Age:</Form.Label>
                                                        <Form.Select className="form-select" aria-label="Default select example 2">
                                                            <option>46-62</option>
                                                            <option>63 </option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <Form.Group className="form-group col-sm-6">
                                                        <Form.Label className="form-label">Country:</Form.Label>
                                                        <Form.Select defaultValue="USA" className="form-select" aria-label="Default select example 3">
                                                            <option>Caneda</option>
                                                            <option>Noida</option>
                                                            <option>USA</option>
                                                            <option>India</option>
                                                            <option>Africa</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <Form.Group className="form-group col-sm-6">
                                                        <Form.Label className="form-label">State:</Form.Label>
                                                        <Form.Select defaultValue="Georgia" className="form-select" aria-label="Default select example 4">
                                                            <option>California</option>
                                                            <option>Florida</option>
                                                            <option>Georgia</option>
                                                            <option>Connecticut</option>
                                                            <option>Louisiana</option>
                                                        </Form.Select>
                                                    </Form.Group> */}
                                                <Form.Group className="form-group col-sm-12">
                                                    <Form.Label className="form-label">Bio:</Form.Label>
                                                    <Form.Control as="textarea" className="form-control" rows={5} style={{ lineHeight: "22px" }} placeholder="Enter Your Bio"
                                                        value={bio} onChange={(e) => setBio(e.target.value)}>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Row>
                                            <Button className="btn btn-primary me-2" onClick={() => {
                                                handleUpdateSubmit()
                                            }}>Submit</Button>
                                            {/* <Button type="reset" className="btn bg-soft-danger">Cancel</Button> */}
                                            {/* </Form> */}
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second" className="fade show">
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="iq-header-title">
                                                <h4 className="card-title">Change Password</h4>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            {/* <Form> */}
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="cpass" className="form-label">Current Password:</Form.Label>
                                                <Link to="/auth/recoverpw" className="float-end">Forgot Password</Link>
                                                <div style={{ position: "relative", marginBottom: 20, display: "flex", justifyContent: "end" }}>
                                                    <Form.Control type={opType} className="form-control" id="cpass" defaultValue=""
                                                        value={oldPassword} onChange={(e) => { setOldPassword(e.target.value) }} />
                                                    <Icon style={{ position: "absolute", marginTop: 7, marginRight: 8 }} icon={opIcon} size={20} onClick={handleOPToggle} />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="npass" className="form-label">New Password:</Form.Label>
                                                <div style={{ position: "relative", marginBottom: 20, display: "flex", justifyContent: "end" }}>
                                                    <Form.Control type={npType} className="form-control" id="npass" defaultValue=""
                                                        value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }} />
                                                    <Icon style={{ position: "absolute", marginTop: 7, marginRight: 8 }} icon={npIcon} size={20} onClick={handleNPToggle} />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="vpass" className="form-label">Verify Password:</Form.Label>
                                                <div style={{ position: "relative", marginBottom: 30, display: "flex", justifyContent: "end" }}>
                                                    <Form.Control type={cpType} className="form-control" id="vpass" defaultValue=""
                                                        value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />
                                                    <Icon style={{ position: "absolute", marginTop: 7, marginRight: 8 }} icon={cpIcon} size={20} onClick={handleCPToggle} />
                                                </div>
                                            </Form.Group>
                                            <Button className="btn btn-primary me-2 " onClick={() => changePassword()}>Submit</Button>
                                            {/* <Button type="reset" className="btn bg-soft-danger">Cancel</Button> */}
                                            {/* </Form> */}
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third" className="fade show">
                                    <Card>
                                        <Card.Header className="card-header d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Email and SMS</h4>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <Form.Group className="form-group row align-items-center">
                                                    <Form.Label className="col-md-3" htmlFor="emailnotification">Email Notification:</Form.Label>
                                                    <Form.Check className="col-md-9 form-check form-switch">
                                                        <Form.Check.Input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked11" defaultChecked />
                                                        <Form.Check.Label className="form-check-label" htmlFor="flexSwitchCheckChecked11">Checked switch checkbox input</Form.Check.Label>
                                                    </Form.Check>
                                                </Form.Group>
                                                <Form.Group className="form-group row align-items-center">
                                                    <Form.Label className="col-md-3" htmlFor="smsnotification">SMS Notification:</Form.Label>
                                                    <Form.Check className="col-md-9 form-check form-switch">
                                                        <Form.Check.Input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked12" defaultChecked />
                                                        <Form.Check.Label className="form-check-label" htmlFor="flexSwitchCheckChecked12">Checked switch checkbox input</Form.Check.Label>
                                                    </Form.Check>
                                                </Form.Group>
                                                <Form.Group className="form-group row align-items-center">
                                                    <Form.Label className="col-md-3" htmlFor="npass">When To Email</Form.Label>
                                                    <Col md="9">
                                                        <Form.Check className="form-check">
                                                            <Form.Check.Input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckDefault12" />
                                                            <Form.Check.Label className="form-check-label" htmlFor="flexCheckDefault12">
                                                                You have new notifications.
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                        <Form.Check className="form-check d-block">
                                                            <Form.Check.Input className="form-check-input" type="checkbox" defaultValue="" id="email02" />
                                                            <Form.Check.Label className="form-check-label" htmlFor="email02">You're sent a direct message</Form.Check.Label>
                                                        </Form.Check>
                                                        <Form.Check className="form-check d-block">
                                                            <Form.Check.Input type="checkbox" className="form-check-input" id="email03" defaultChecked />
                                                            <Form.Check.Label className="form-check-label" htmlFor="email03">Someone adds you as a connection</Form.Check.Label>
                                                        </Form.Check>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group className="form-group row align-items-center">
                                                    <Form.Label className="col-md-3" htmlFor="npass">When To Escalate Emails</Form.Label>
                                                    <Col md="9">
                                                        <Form.Check className="form-check">
                                                            <Form.Check.Input className="form-check-input" type="checkbox" defaultValue="" id="email04" />
                                                            <Form.Check.Label className="form-check-label" htmlFor="email04">
                                                                Upon new order.
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                        <Form.Check className="form-check d-block">
                                                            <Form.Check.Input className="form-check-input" type="checkbox" defaultValue="" id="email05" />
                                                            <Form.Check.Label className="form-check-label" htmlFor="email05">New membership approval</Form.Check.Label>
                                                        </Form.Check>
                                                        <Form.Check className="form-check d-block">
                                                            <Form.Check.Input type="checkbox" className="form-check-input" id="email06" defaultChecked />
                                                            <Form.Check.Label className="form-check-label" htmlFor="email06">Member registration</Form.Check.Label>
                                                        </Form.Check>
                                                    </Col>
                                                </Form.Group>
                                                <Button type="submit" className="btn btn-primary me-2">Submit</Button>
                                                <Button type="reset" className="btn bg-soft-danger">Cancel</Button>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth" className="fade show">
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Manage Contact</h4>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="cno" className="form-label">Contact Number:</Form.Label>
                                                    <Form.Control type="text" className="form-control" id="cno" defaultValue="001 2536 123 458" />
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="email" className="form-label">Email:</Form.Label>
                                                    <Form.Control type="text" className="form-control" id="email" defaultValue="Bnijone@demo.com" />
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="url" className="form-label">Url:</Form.Label>
                                                    <Form.Control type="text" className="form-control" id="url" defaultValue="https://getbootstrap.com" />
                                                </Form.Group>
                                                <Button type="submit" className="btn btn-primary me-2">Submit</Button>
                                                <Button type="reset" className="btn bg-soft-danger">Cancel</Button>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Modal show={showCroper} onHide={handleCloseCroper} size="lg" style={{ marginTop: 100 }}>
                                    <Modal.Header className="d-flex justify-content-between">
                                        <h5 className="modal-title" id="post-modalLabel">Resize Profile Image </h5>
                                        <button type="button" className="btn btn-secondary lh-1" onClick={handleCloseCroper} ><span className="material-symbols-outlined">close</span></button>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="d-flex align-items-center" style={{ height: "300px" }}>
                                            <Cropper
                                                image={imageForCrop}
                                                crop={crop}
                                                zoom={zoom}
                                                aspect={4 / 4}
                                                cropShape={"rect"}
                                                onCropChange={setCrop}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                            />
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="primary" className="d-block w-100 mt-3"
                                            onClick={() => resizeComplete()}
                                        >Update Profile Picture</Button>
                                    </Modal.Footer>
                                </Modal>
                                {/* {
                                    showCroper ?
                                        <Cropper
                                            image={imageForCrop}
                                            crop={crop}
                                            zoom={zoom}
                                            aspect={4 / 3}
                                            onCropChange={setCrop}
                                            onCropComplete={onCropComplete}
                                            onZoomChange={setZoom}
                                        /> : null
                                } */}
                            </Tab.Content>
                            {/* </div> */}
                        </Col>
                    </Row>
                </Tab.Container>
                {/* {
                    <Modal show={show} onHide={handleClose} size="sg" style={{ marginTop: 100 }}>
                        <Modal.Header className="d-flex justify-content-between">
                            <h5 className="modal-title" id="post-modalLabel">Create Post</h5>
                            <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="d-flex align-items-center">
                                <div className="user-img">
                                    <img loading="lazy" src={user9} alt="userimg" className="avatar-60 rounded-circle img-fluid" />
                                </div>
                                <form className="post-text ms-3 w-100" action="">
                                    <input type="text" className="form-control rounded" placeholder="Write something here..." style={{ border: "none" }} />
                                </form>
                            </div>
                            <Button variant="primary" className="d-block w-100 mt-3">Create Post</Button>
                        </Modal.Body>
                    </Modal>
                } */}


            </Container>


        </>
    )

}

export default UserProfileEdit;